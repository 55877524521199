import React, { Component } from "react";
import { ContactUs, Alert } from "master-react-lib";
import axios from "axios";

import FacebookIcon from "../assets/imgs/socialicons/FacebookRounded.svg";
import YouTubeIcon from "../assets/imgs/socialicons/YouTubeRounded.svg";
import TwitterIcon from "../assets/imgs/socialicons/TwitterRounded.svg";
import WhatsAppIcon from "../assets/imgs/socialicons/WhatsappRounded.svg";
import WhatsAppIconButton from "../assets/imgs/socialicons/WhatsappRoundedGreen.svg";

import DoneImg from "../assets/imgs/Done.png";
import CloseIcon from "../assets/imgs/cancel.png";

function ContactSection(props) {
  const onContactFormSubmit = (contactFormData) => {
    console.log("contactFormData", contactFormData);

    const postData = {
      first_name: contactFormData.firstName,
      last_name: contactFormData.lastName,
      email: contactFormData.email,
      message: contactFormData.message,
      inquire_type: contactFormData.select,
      org_name: "ZamZam Hajj",
      email_logo: "https://media.zamzamhajj.com/images/zamzamlogo.7bebc42b.png",
    };

    if (!validateEmail(postData.email)) {
      alert("Please provide valid email address");
      return "";
    }

    const orgBaseUrl = "https://dev.afnanilab.com/contactUs";

    return new Promise((resolve, reject) => {
      axios
        .post(`${orgBaseUrl}`, postData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          resolve(res);
          alert("Your message sent successfully");
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const officeDetails = {
    primaryPhone: "+1 (917) 540-7944",
    // secondaryPhone: "+1 (718) 348-1853",
    address: "1049 Glenmore Ave, Brooklyn, NY 11208",
    email: "yeszamzam@gmail.com",
  };

  const socialLinks = {
    facebook:
      "https://www.facebook.com/pages/category/Tour-Guide/ZamZam-Hajj-Kafela-104054951900488/",
    youtube: "https://www.youtube.com/channel/UCfe06KuigZlc3wtYpXJNRHw",
    // twitter: "https://www.twitter.com",
  };

  const contacts = [
    // { name: "Aminur Rahman", address: "Ozone park, ny", phone: "(929) 254-9138" },
    // { name: "Imam Obaidul Haque", address: "Bronx, ny", phone: "(347) 320-7218" },
    {
      name: "Imam Azim Uddin",
      address: "Pennsylvania",
      phone: "(215) 917-9524",
    },
    { name: "Nurul Islam", address: "Florida", phone: "(561) 281-8966" },
    {
      name: "Hafiz Rayhan Uddin",
      address: "Detroit, Michigan",
      phone: "(313) 788-4913",
    },
    {
      name: "Abdul Mukit Azad",
      address: "Los angeles, CA",
      phone: "(323) 309-9827",
    },
  ];

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      {/* <div className='contactAlert' open={open} autoHideDuration={1000} onClose={handleClose}>
            <Alert
                message="Your message has been sent!"
                show={true}
                onCloseClick={handleClose}
                closeIcon={CloseIcon}
                doneIcon={DoneImg}
            />
            </div> */}

      <ContactUs
        title={"Contact Us"}
        description={"Our team is always available to help, inshaAllah."}
        onContactFormSubmit={onContactFormSubmit}
        selectOptions={[{ title: "Hajj 2024" }, { title: "Umrah 2024" }]}
        officeDetails={officeDetails}
        socialLinks={socialLinks}
        contacts={contacts}
        whatsAppIconButtonLink={"#"}
        FacebookIcon={FacebookIcon}
        YouTubeIcon={YouTubeIcon}
        TwitterIcon={TwitterIcon}
        //WhatsAppIcon={WhatsAppIcon}
        //WhatsAppIconButton={WhatsAppIconButton}

        ButtonLabel="SEND MESSAGE"
        ButtonColor="#56B149"
        //disableBtn="disabled"
      />
    </div>
  );
}

export default ContactSection;
