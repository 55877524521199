import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Brightness1Icon from "@material-ui/icons/Brightness1";

import "../assets/css/style.css";

class Requirements extends Component {
  render() {
    return (
      <div className="RequirementsContainer GreenPattren SectionContainer">
        <Grid container spacing={4}>
          <Grid item sm={5} xs={12}>
            <h1 className="sectionTitle textWhite">REQUIREMENTS</h1>
            <p>
              Please, be assure to complete and provide all the requirements
              correctly to avoid delay travel processing.
            </p>
            <p className="RequirmentDescription">
              Email us all documents at{" "}
              <a href="mailto:yeszamzam@gmail.com">yeszamzam@gmail.com</a>.
              {/* or
              send via WhatsApp number{" "}
              <a href="tel:(917) 348-6417">(917) 540-7944.</a> */}
            </p>
          </Grid>

          <Grid item sm={7} xs={12}>
            <div className="RequirementsList">
              <li>
                <Brightness1Icon /> 3 passport size color photo with white
                background
              </li>
              <li>
                <Brightness1Icon /> Passport valid at least 6 months prior to
                Hajj flight
              </li>
              {/* <li>
                <Brightness1Icon /> Fully vaccinated and proof of all COVID-19
                vaccines
              </li> */}
              <li>
                <Brightness1Icon /> Meningococcal Vaccine - Meningitis
                Vaccination Certificate
              </li>
              <li>
                <Brightness1Icon /> Copy of Green Card for Non-US/American
                Passport
              </li>
              <li>
                <Brightness1Icon /> Copy of Marriage Certificate for wife &
                Birth Certificate for child
              </li>
            </div>
          </Grid>
        </Grid>

        <div className="masjidImg"></div>
      </div>
    );
  }
}

export default Requirements;
