import React from "react";
import { CheckoutPaymentZamZam, ZamzamOrderSummary } from "master-react-lib";
import MasterCardImg from "../assets/imgs/masterCardIcon.png";
import VisaCardImg from "../assets/imgs/visa-icon.png";
import NewCardImg from "../assets/imgs/new-card.png";
import DiscoverCardImg from "../assets/imgs/discovercard.png";
import AmericanExpressImg from "../assets/imgs/american-express.png";
import DinersCardImg from "../assets/imgs/dinerscard.png";
import HelpImg from "../assets/imgs/help-24px.svg";
import HeaderSection from "./Common/HeaderSection";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import Grid from "@material-ui/core/Grid";
import CreditCard from "../assets/imgs/credit-card.png";
import CashIcon from "../assets/imgs/CashIcon.png";
import Paycheck from "../assets/imgs/paycheck.svg";

function CheckOut(props) {
  const [cardDetails, setcardDetails] = React.useState({
    cardNumber: "",
    expiration: "",
    code: "",
  });

  const [addressDetails, setaddressDetails] = React.useState({
    firstName: "",
    lastName: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const [saveCardDetails, setsaveCardDetails] = React.useState(false);

  let selectedCardDetails = {
    firstName: "Sheikh",
    lastName: "Rehman",
    line1: "Street Address",
    line2: "",
    city: "City",
    state: "State",
    postalCode: "Zip Code",
    country: "United States",
  };

  const handlePaymentSelectChange = (selectedPaymentCard) => {
    console.log("selectedPaymentCard: ", selectedPaymentCard);
  };

  const onPlaceOrderSubmit = (cardDetails) => {
    console.log(cardDetails);
  };

  const onEditBillingDetailClick = () => {
    console.log("onEditBillingDetailClick");
  };

  const creditCardsList = [
    { cardName: "Visa-4242", cardNumber: "4242", cardImage: VisaCardImg },
    {
      cardName: "Master Card-9213",
      cardNumber: "9213",
      cardImage: MasterCardImg,
    },
  ];

  return (
    <div className="MainContainer">
      <HeaderSection />
      <div className="innterContaineCheckout">
        <Grid container spacing={3}>
          <Grid item md={8} sm={8} xs={12}>
            <CheckoutPaymentZamZam
              creditCardsList={creditCardsList}
              handlePaymentSelectChange={handlePaymentSelectChange}
              onPlaceOrderSubmit={onPlaceOrderSubmit}
              cardDetails={cardDetails}
              setcardDetails={setcardDetails}
              addressDetails={addressDetails}
              setaddressDetails={setaddressDetails}
              saveCardDetails={saveCardDetails}
              setsaveCardDetails={setsaveCardDetails}
              selectedCardDetails={selectedCardDetails}
              privacyPolicyLink={"/privacy-policy"}
              termsOfUseLink={"/terms-of-use"}
              onEditBillingDetailClick={onEditBillingDetailClick}
              visaCardImage={VisaCardImg}
              masterCardImage={MasterCardImg}
              newCardImage={NewCardImg}
              discoverCardImage={DiscoverCardImg}
              americanExpressImage={AmericanExpressImg}
              dinersCardImage={DinersCardImg}
              helpImage={HelpImg}
              CreditCard={CreditCard}
              CashIcon={CashIcon}
              Paycheck={Paycheck}
              StepTitle_1="Primary Attendee"
              StepTitle_2="Attendee(s) Details"
              StepTitle_3="Payment"
            />
          </Grid>

          <Grid item md={4} sm={4} xs={12}>
            <ZamzamOrderSummary />

            <div className="wgtInfoContainer">
              <li>
                <h2>
                  <InfoRoundedIcon /> HAJJ PACKAGE FEE
                </h2>
                <p>
                  Hajj package prices are not yet fixed due to confirmation
                  pending from “The Ministry of Hajj and Umrah”
                </p>
              </li>

              <li>
                <h2>
                  <InfoRoundedIcon /> PRE-REGISTRATION FEE
                </h2>
                <p>
                  Currently we are accepting pre-registration of $1000/per
                  member which{" "}
                  <strong> will be adjusted from total Hajj fee.</strong>
                </p>
              </li>

              <li>
                <p>
                  <strong>Pre-registration fee is 100% refundable.</strong>
                </p>
                <h2>
                  <InfoRoundedIcon /> CONTACT US
                </h2>
                <p>
                  If you have queries, please call
                  <br /> Br. Delwar at +1 (917) 540-7944.
                </p>
              </li>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default CheckOut;
