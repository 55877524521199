import React, { Component } from "react";

function HajjRegistration(props) {
  return (
    <div className="HajjRegistration">
      <h2>New Umrah packages for 2024</h2>
      {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSfoo-L-PYKGFgypidoRgh25POqvVNoMEfVevRRP_67B46TnrA/viewform" target="_blank">REGISTER</a> */}
      <a href="https://www.facebook.com/ZamzamHajjUSA/" target="_blank">
        View Details
      </a>
    </div>
  );
}

export default HajjRegistration;
