import React from "react";
import { PageTitleOne } from "master-react-lib";
import backgroundPattern from "../../assets/imgs/Pattern.svg";

function PageTitle(props) {
  return (
    <PageTitleOne
      Title={props.Title}
      SubTitle={props.Subtitle}
      color="#fff"
      backgroundcolor="#28b356"
      backgroundPattern={backgroundPattern}
    />
  );
}

export default PageTitle;
