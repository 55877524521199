import React, { useState } from "react";
import TextFields from "@material-ui/core/TextField";
// import TextFieldCom from '@mui/material/TextField';
import "../styles/TextFeild.css";

function TextField(props) {
  const {
    label,
    required,
    email,
    error,
    placeholder,
    value,
    handleChange,
    handleValidation,
    helperText,
    count,
  } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState("");

  const handleOnChange = (event) => {
    const value = event.target.value;
    let error = false;

    // required field validation
    if (required && value?.length > 0) {
      setValidationError(false);
      setValidationText("");
    } else {
      error = false;
      setValidationError(false);
      setValidationText("");
      // error = true;
      // setValidationError(true);
      // setValidationText("Required");
    }

    // email field validation
    if (email) {
      if (emailValidation(value)) {
        setValidationError(false);
        setValidationText("");
      } else {
        error = true;
        setValidationError(true);
        setValidationText("Email is not valid");
      }
    }

    handleChange(value);

    if (handleValidation) {
      handleValidation(error);
    }
  };

  const emailValidation = (email) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      return false;
    }
    return true;
  };

  return (
    <div className="TextField">
      <div className="FieldLabel">
        {label} {required && label && <span>*</span>}
      </div>
      <TextFields
        id="outlined-basic"
        variant="outlined"
        error={error || validationError}
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        helperText={helperText || validationText}
        inputProps={{
          maxLength: count,
        }}
      />
      <span className="FieldCount">
        {count && value && (
          <span style={{ color: "#B6B9B9", alignSelf: "flex-end" }}>
            {" "}
            CHAR {value.length} / {count}
          </span>
        )}
      </span>
    </div>
  );
}

export default TextField;
