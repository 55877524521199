import React, { Component } from 'react';
import { CarouselOne, CardSeven   } from "master-react-lib";
import event_one from '../assets/imgs/events/ZamZam-Hajj-Kafela_2022.webp';
import RamadhanFlayer from '../assets/imgs/events/ZamZam-Umrah-2022-Ramadhan.webp';
import ZamZamUmrah from '../assets/imgs/events/ZamZam-Umrah-2022-May.webp';


import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import EventDetailsModalOne from './EventDetailsModalOne';
import EventDetailsModalTwo from './EventDetailsModalTwo';
import EventDetailsModalThree from './EventDetailsModalThree';
import EventDetailsModalFour from './EventDetailsModalFour';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));



function UpcomingEventSection(props) {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => { setOpen(true);};
    const handleClose = () => { setOpen(false);};

    const [open_2, setOpen_2] = React.useState(false);
    const handleOpen_2 = () => {  setOpen_2(true);};
    const handleClose_2 = () => { setOpen_2(false);};

    const [open_3, setOpen_3] = React.useState(false);
    const handleOpen_3 = () => {  setOpen_3(true);};
    const handleClose_3 = () => { setOpen_3(false);};

    const [open_4, setOpen_4] = React.useState(false);
    const handleOpen_4 = () => {  setOpen_4(true);};
    const handleClose_4 = () => { setOpen_4(false);};



    const carouseldata = [

        {
            sliderItem: <a onClick={handleOpen}><CardSeven
                image={event_one}
                title="Hajj Kafela 2022"
                subTitle="Makkah & Medina"
                time={<p>JUNE 23/24 - JULY 15/16 </p>}
                buttonText="View Details"
            />
            </a>
        },
    
        // {
        //     sliderItem: <a onClick={handleOpen_2}><CardSeven
        //         image={RamadhanFlayer}
        //         title="Ramadhan First 10 Days"
        //         subTitle="Makkah & Medina"
        //         time={<p>MARCH 30/31 - APRIL 9/10</p>}
        //         buttonText="View Details"
        //     />
        //     </a>
        // },

        
        {
            sliderItem: <a onClick={handleOpen_3}><CardSeven
                image={RamadhanFlayer}
                title="Ramadhan Last 10 Days"
                subTitle="Makkah & Medina"
                time={<p>APRIL 18/19 - MAY 1/2</p>}
                buttonText="View Details"
            /></a>
        },

        {
            sliderItem: <a onClick={handleOpen_4}><CardSeven
                image={ZamZamUmrah}
                title="Last Umrah Before Hajj 2022"
                subTitle="Makkah & Medina"
                time={<p>MAY 4/5 - MAY 12/13</p>}
                buttonText="View Details"
            />
            </a>
        },
    
    
    ];
    


    return (
        <div className="SectionContainer">
            <div >
                <CarouselOne
                    title="Upcoming Events"
                    carouseldata={carouseldata}
                    mobileview={2}
                    desktopview={2}
                />
            </div>


            <div>

                <Modal
                    aria-labelledby="spring-modal-title"
                    aria-describedby="spring-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className='ModalInnerContainer'>
                            <div className='ModalClose' onClick={() => handleClose()}><CloseRoundedIcon /></div>
                            <EventDetailsModalOne />
                        </div>
                    </Fade>

                </Modal>


                <Modal
                    aria-labelledby="spring-modal-title"
                    aria-describedby="spring-modal-description"
                    className={classes.modal}
                    open={open_3}
                    onClose={handleClose_3}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open_3}>
                        <div className='ModalInnerContainer'>
                            <div className='ModalClose' onClick={() => handleClose_3()}><CloseRoundedIcon /></div>
                            <EventDetailsModalThree />
                        </div>
                    </Fade>

                </Modal>

                <Modal
                    aria-labelledby="spring-modal-title"
                    aria-describedby="spring-modal-description"
                    className={classes.modal}
                    open={open_4}
                    onClose={handleClose_4}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open_4}>
                        <div className='ModalInnerContainer'>
                            <div className='ModalClose' onClick={() => handleClose_4()}><CloseRoundedIcon /></div>
                            <EventDetailsModalFour />
                        </div>
                    </Fade>

                </Modal>

                <Modal
                    aria-labelledby="spring-modal-title"
                    aria-describedby="spring-modal-description"
                    className={classes.modal}
                    open={open_2}
                    onClose={handleClose_2}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open_2}>
                        <div className='ModalInnerContainer'>
                            <div className='ModalClose' onClick={() => handleClose_2()}><CloseRoundedIcon /></div>
                            <EventDetailsModalTwo />
                        </div>
                    </Fade>

                </Modal>

            </div>





        </div>
    );
}

export default UpcomingEventSection;