import React from "react";
import HeaderSection from "../Common/HeaderSection";
import { Grid } from "@material-ui/core";
import "../styles/Tos.css";
import FooterSection from "../Common/FooterSection";

const TermsCondition = () => {
  return (
    <div>
      <div className="MainContainer">
        <HeaderSection />
        <div className="innterContainer">
          <Grid container style={{ padding: "16px" }}>
            <Grid item xs={12}>
              <div className="TosContainer">
                <h1 className="InnerPageTitle">Terms & Conditions</h1>
                <span className="TosDate">LAST UPDATED: JAN 13, 2024</span>
                <br />
                <br />
                <p>
                  Terms & Conditions User agreement between the user (User, you)
                  and Moresand Limited T/A ZamZam Hajj (we).
                </p>
                <br />
                <p>
                  Please read these terms and conditions of use (Terms and
                  Conditions) before using ZamZam Hajj website (Site) and making
                  any booking. You sanction the Terms and Conditions when you
                  visit the Site, without qualification. If you disagree with
                  any part of the Terms and Conditions, you may not draw on the
                  Site in any way or make a booking. Each and every one alliance
                  regarding customer service or your booking should be sent to
                  ZamZam Hajj or to{" "}
                  <a href="mailto:yeszamzam@gmail.com">yeszamzam@gmail.com</a>
                </p>
                <br />
                <p>Contents of these Terms and Conditions: </p>
                <ol>
                  <li>Your agreement and contract </li>
                  <li>Conditions for Delivery of Your Booking </li>
                  <li>Cancellations or Booking Changes </li>
                  <li>Terms and Conditions to Flight Booking </li>
                  <li>
                    Payment Conditions 6. Passport, Visas and Health
                    Requirements{" "}
                  </li>
                  <li>Other Generally Applicable Terms and Conditions </li>
                </ol>
                {/* =============== */}
                <br />
                <h2>1- Your Contract and Agreement: </h2>
                <p>
                  A ‘booking’ means any order for products or services you make
                  on our Site which is received by us and we offer you. Receipt
                  will be made by ZamZam Hajj (and a contract concluded) when we
                  have received complete payment from you and sent a
                  verification email (from either ZamZam Hajj or the relevant
                  Travel Agent). All travel products and services featured on
                  the Site are subject to accessibility. ZamZam Hajj offers lots
                  of diverse products and the explicit terms and conditions
                  relating to your booking depend on whether you book a Flight
                  or other particular Package, hotels, holiday’s lodges, a
                  combination of products. ZamZam Hajj is acting as negotiator
                  for the third party suppliers such as airlines, hotels,
                  insurance and car rental companies, package holiday suppliers
                  and others (Travel Supplier). When you make a booking via the
                  Site, and your chosen travel product or service is accessible,
                  the contract will be between the Travel Supplier and you.
                  ZamZam Hajj is not a party to the contractual relationship;
                  (c) ‘Other Products’ are those separate products – e.g.
                  flights and hotels purchased and paid for at dissimilar times,
                  and holidays packaged by another supplier. When you make a
                  booking via the Site, and your preferred travel product or
                  service is accessible, the contract will be between the Travel
                  Supplier and you. ZamZam Hajj is not a party to the
                  contractual relationship.{" "}
                </p>
                <p>
                  ZAMZAM HAJJ STRONGLY RECOMMENDS THAT YOU TAKE OUT INSURANCE
                  FOR ANY UMRAH/HAJJ PACKAGE, ESPECIALLY AS THERE MAY BE
                  CIRCUMSTANCES WHERE NEITHER ZAMZAM HAJJ NOR THE TRAVEL
                  SUPPLIER CAN ACCEPT LIABILITY (e.g. DELAYS OR CANCELLATIONS
                  BEYOND OUR CONTROL).
                </p>
                <p>
                  Travel Suppliers: ZamZam Hajj acts as disclose Agent for third
                  party suppliers and for the use of these terms and conditions
                  tour operators, hotels, hotel chains and hotel aggregators,
                  insurance and car suppliers, plus all airlines, shall be
                  incorporated in the term ‘Travel Supplier’.
                </p>
                <p>
                  In these cases your contract is between you and the Travel
                  Supplier, and any queries or concerns relating to the product
                  should be addressed to them. You will glance the name and
                  address, plus contact details, for most suppliers at the
                  bottom of the more info segment for each product and for all
                  suppliers in any verification e-mail we or the Travel Supplier
                  send you.
                </p>
                <p>
                  ZamZam Hajj does not presume any liability for the travel
                  products and services provided by the Travel Supplier and
                  makes no representations or warranties (express or implied)
                  about the aptness or quality of travel products and services
                  featured on the Site.{" "}
                </p>
                <br />
                <h2>Travel Supplier Conditions: </h2>
                <p>
                  The following applies to all products or services booked via
                  our Site: The Travel Supplier’s terms and conditions
                  (including airlines’ terms and conditions of carriage) will
                  apply in addition to those set out here. The Travel Supplier’s
                  terms and conditions may include necessities relating to
                  payment procedures, default, liability, cancellations, changes
                  of bookings and refunds (if available) and any other
                  restrictions. as a result, as you move through the Site you
                  will see links to terms applying to specific Travel Supplier
                  products (you are advised to read those terms carefully) –
                  however, please refer to your particular Travel Supplier for
                  full details as to the applicable terms and conditions. You
                  are responsible for complying with any airline or other Travel
                  Supplier conditions in relation to check-in times,
                  reconfirmation of flights, or other matters.{" "}
                </p>
                <br />
                <h2>Flights Booking Terms & Conditions: </h2>
                <p>
                  In particular, please note that in relation to air fares there
                  are additional terms specific to that fare. For example,
                  ‘economy restricted’ tickets are normally non- changeable and
                  non-refundable. Flights must be used in the order set out in
                  your itinerary – e.g. a failure to use the outbound flight or
                  first stage of a journey could invalidate the rest of the
                  ticket.{" "}
                </p>
                <p>
                  Please note that, in relation to flight tickets, the
                  recommended minimum check-in time is 120 minutes prior to
                  departure for international flights and 90 minutes for
                  domestic flights. Some airlines require you to reconfirm your
                  return flight booking at least 72 hours before travel.{" "}
                </p>
                <p>
                  Failure to reconfirm your flight booking directly with the
                  airline may result in cancellation. In addition, the majority
                  of airlines reserve the right to make schedule changes and
                  cancel confirmed bookings.{" "}
                </p>
                <p>
                  While ZamZam Hajj, as Agent, is not responsible for such
                  schedule changes or cancellations, we will provide you with
                  any reasonable assistance you require via our customer
                  services. We strongly recommend (particularly if you are
                  flying economy class), that you check in early if you have
                  particular seat requests. ZamZam Hajj has no control over the
                  allocation of seats, even if pre-booked with the airline and
                  can make no guarantee that specific seats will be available on
                  departure. ZamZam Hajj is not responsible for the costs of any
                  transfers between airports or terminals that you may incur.
                  Flights priced at special fares or best prices may not take
                  the most direct route. Some itineraries require a change of
                  aircraft en-route. A flight that is described as direct is one
                  where there is no need to change aircraft during the journey.
                  However, stops may be made en-route for re-fuelling or to let
                  passengers on and/or off. The times given are given on the
                  24-hour clock system, approximate and based on outbound
                  flights.{" "}
                </p>
                <p>
                  Direct (Flight): Flights that must keep the similar flight
                  number throughout, though it may stop for a change of aircraft
                  (equipment). And they are different from nonstop flights,
                  which travel directly from the departing airport to the
                  absolute destination without stopping for connections or
                  layovers.{" "}
                </p>
                <p>
                  Nonstop (Flight): Those Flight, that travels directly from the
                  departure airport to the final destination without stopping
                  for links or layovers. And they are dissimilar from direct
                  flights, which only must carry on the same flight number all
                  through, though it may stop for a change of aircraft.{" "}
                </p>
                <p>General: </p>
                <p>
                  Taxes are part of the booking process for flight tickets or
                  hotels you are given the option to enter the details of any
                  relevant frequent flyer or hotel loyalty programmed of which
                  you are a member. Please note that any such frequent flyer or
                  hotel loyalty programmed is subject to the terms and
                  conditions of the airline or hotel through which it is
                  offered. Those terms and conditions may not give rewards on
                  all types of fare and class of service.{" "}
                </p>
                <p>
                  Please speak to the airline or hotel in charge of your
                  frequent flyer or hotel loyalty programmed if you are unsure
                  of its terms and conditions. Please note that our Travel
                  Suppliers have their own rules and regulations with regard to
                  loyalty cards. If you have any questions, please contact them
                  directly. In relation to the ‘special requests’ options
                  relating to any product (e.g. meals, disabled facilities,
                  child seats etc.), ZamZam Hajj does not guarantee any such
                  requests but will pass these requests on to the Travel
                  Supplier/hotel. It is your liability to confirm with the
                  Travel Supplier or hotel whether such special requests can be
                  fulfilled.
                </p>
                <h2>
                  2. Delivery of Your Booking The following applies to all
                  products or services booked via our Site:{" "}
                </h2>
                <p>
                  E-tickets Throughout the booking process, you will be given
                  the option of an e-ticket or paper ticket. Please note that
                  airlines have their individual rules and regulations regarding
                  e-ticketing. ZamZam Hajj cannot be held responsible for
                  non-compliance with these rules and regulations and strongly
                  recommends that you check these details with your airline
                  before travel. Ticket Delivery ZamZam Hajj relies on the
                  information provided by you as being correct and therefore
                  cannot be held responsible if your paper tickets are lost due
                  to an incorrect address or if your e-ticket does not arrive
                  due to an incorrect email address or junk email settings. It
                  is your responsibility to notify us promptly if you change
                  your email address or contact telephone number. Additionally,
                  please verify that the name on your passport matches the name
                  on your ticket and/or booking confirmation. Please note that
                  ZamZam Hajj does not deliver paper tickets outside the USA. If
                  you have any special delivery requirements (such as flats with
                  security gates), please inform us immediately after booking.
                  It is your responsibility to inform us if you have not
                  received your tickets. If you have any other bookings, either
                  with ZamZam Hajj or another agent, it is your responsibility
                  to let us know that you need tickets from us within a specific
                  time frame. ZamZam Hajj recommends doing this before
                  completing the booking to determine if express delivery is
                  required.
                </p>
                <p>
                  Note that some airlines may apply a charge and follow a
                  specific procedure for reissuing lost tickets or tickets
                  delayed in the post. This charge and procedure will vary
                  depending on the airline you are traveling with. We use Royal
                  Mail 1st Class Delivery for tickets. If you do not receive
                  your tickets within 7 working days of your booking
                  confirmation email, please contact us—but not before, unless
                  your departure is within this time.{" "}
                </p>
                <p>
                  After the 7 working days, contact us within 48 hours. If you
                  contact ZamZam Hajj within this 48-hour timeframe, we will
                  cover the costs for reissuing. If you contact us outside of
                  the 48 hours, you will be responsible for any costs. Express
                  Next Day Delivery through City Link guarantees delivery by
                  18:00 the following day. Contact our call center within 48
                  hours of your confirmation email if you still have not
                  received tickets—but not before. Failure to contact within
                  this 48-hour timeframe may result in costs from the airline,
                  which you would be accountable for paying.{" "}
                </p>
                <p>
                  You can trace the progress of your delivery on the courier
                  company’s website using the reference provided in the email
                  confirming the dispatch of your tickets. In exceptional
                  circumstances, ZamZam Hajj may be unable to issue airline
                  tickets for confirmed bookings due to ticketing restrictions
                  beyond its control. If this occurs, we will attempt to inform
                  you within 48 hours and arrange a refund or an alternative. If
                  you choose an alternative and it is more expensive than your
                  original booking, you may be responsible for paying the
                  difference. Please note that you may be required to produce
                  your booking number and/or confirmation email to the relevant
                  Travel Supplier as evidence of your booking.{" "}
                </p>
                <br />
                <h2>
                  3. Cancellations or Modifications Terms The following applies
                  to all products or services booked via our Site:{" "}
                </h2>
                <p>
                  Your ability to cancel or modify a booked travel product or
                  service, and the method for doing so, will depend on the
                  specific Travel Supplier’s or hotel’s terms and conditions. It
                  may not be possible to cancel or modify some products or
                  services, or there may be specific requirements you need to
                  meet. For flight bookings, you can possibly cancel or modify
                  your booking by calling the ZamZam Hajj Call Centre or
                  emailing to{" "}
                  <a href="mailto:mailto:yeszamzam@gmail.com">
                    yeszamzam@gmail.com.
                  </a>{" "}
                </p>
                <p>
                  For car rental, the following amendment and cancellation terms
                  will apply: for reservations canceled or amended within seven
                  days of the booking date, ZamZam Hajj will impose a charge of
                  15 per rental.{" "}
                </p>
                <p>
                  For reservations canceled or amended more than seven days
                  after the booking date, ZamZam Hajj will impose a charge of 40
                  per rental.{" "}
                </p>
                <p>
                  In some circumstances, ZamZam Hajj or the Travel Supplier may
                  be unable to cancel or modify a travel product or service
                  unless we receive a written request together with payment of
                  any extra fees and associated charges. Please note that, in
                  relation to flights, a ‘no-show’ for a flight may result in
                  your ticket being canceled by the airline and therefore not
                  refundable. Accordingly, if you desire to change a flight
                  booking close to the departure time, we strongly recommend
                  that you contact ZamZam Hajj and obtain written email
                  confirmation for that change before opting not to travel on
                  the original flight.{" "}
                </p>
                <p>
                  For car rental, if you fail to cancel your reservation before
                  the pick-up time and do not collect the vehicle on the pick-up
                  date, or if you fail to comply with the pick-up terms (see car
                  supplier terms and conditions), ZamZam Hajj reserves the right
                  to make a ‘no-show’ charge of 100% of the total car rental
                  booking value. General Regarding your flight tickets, please
                  note that the deposits for your flights will be totally
                  non-refundable.{" "}
                </p>
                <p>
                  ZamZam Hajj won’t be responsible for any refunds if you want
                  to cancel your trip. Please make sure your passport is valid
                  for at least 6 months before applying for Umrah/Hajj. Your
                  passports will be sent to the Saudi Embassy for a Visa, but in
                  case you don’t get your visa for any reason, ZamZam Hajj won’t
                  be liable for that. It’s the Saudi Embassy that issues the
                  Visa, not ZamZam Hajj. Visa Cost might be different sometimes
                  at the time of making a booking, and it may go up by the time
                  your visa is applied/issued, please make sure you would have
                  to top up whatever the difference might be. Please be aware
                  that in case of any visa rejection, we can only apply for
                  hotel and flight cancellation, and ZamZam Hajj would only
                  refund you the amount according to the terms and conditions,
                  and under no circumstances is ZamZam Hajj responsible to give
                  you 100% of the refund. The Hotels you have been quoted will
                  be available as per availability; please be noted that you
                  will be given the same or something similar to the hotels you
                  have been quoted. Under no circumstances are your deposits
                  refundable, although under certain criteria we may refund
                  partially if we have something from the Vendor in Saudi
                  Arabia.{" "}
                </p>
                <p>
                  Please make sure, anytime you cancel your Umrah/Hajj or a
                  Holiday Package, your full money is non-refundable. The
                  deposit you pay for Hajj will be totally
                  non-refundable/non-endorsable. Please be aware that the
                  failure to use the outbound flight or first segments of the
                  flight’s itinerary could invalidate the rest of the ticket.{" "}
                </p>
                <p>
                  Make sure you reach the airport at least 3 hours before
                  departure of international flights to have a safe check-in.
                  Please check all your traveling documents before traveling to
                  avoid any inconveniences at the airport as the company won’t
                  be responsible for any issues in this regard. The airlines
                  reserve the right to make schedule changes and cancel
                  confirmed bookings.{" "}
                </p>
                <p>
                  ZamZam Hajj, as your travel consultant, is not responsible for
                  such schedule changes or cancellations; we will provide you
                  with any reasonable assistance you require through our
                  customer support department. Please be informed that the
                  schedule change is from the airline and that in this case, we
                  can only get your tickets revalidated/reissued according to
                  the next flights available only after airline’s approval, and
                  that in this case, regarding the hotel accommodation, the
                  company can only try to accommodate accordingly, and in case
                  of any fare/price difference, the customer is liable to pay.
                  Please check your flight status online or with your agent
                  within 7 days prior to departure. As your Travel Service
                  provider, we would like to remind you of the importance of
                  travel insurance. However, we do not provide insurance and
                  suggest insuring yourselves, as we and our representatives
                  will not take any responsibility for loss or damage of
                  luggage, loss of property or personal belongings stored in the
                  hotel or elsewhere. This also includes unexpected medical
                  uncertainty arising abroad and personal liability claims. The
                  normal check-in time is 1400 hrs., and check-out time is 1200
                  noon.
                </p>
                <p>
                  Please be informed of the strict check-in and checkout
                  policies. Early check-in and late check-out will always be
                  subject to availability. Lastly, any changes that you wish to
                  make from the agreed package must be notified to your travel
                  agent to see if alterations are possible. There may be a
                  charge for this. The Company reserves the right to cancel the
                  package on a non-refundable basis if the payment plan is not
                  met by the customer according to the one originally set at the
                  time of booking. We acquire hotel and accommodation star
                  ratings from a variety of sources and offer them as a general
                  guide. However, these are not necessarily the official local
                  rating, and you should be aware that standards could vary
                  between hotels and accommodation of the same class in
                  different countries and even in the same country. Please read
                  the terms & condition carefully because signing the booking
                  confirmation means that you have fully agreed to the Terms &
                  conditions of ZamZam Hajj.
                </p>
                <p>
                  Visa Processing: The company's obligations under these booking
                  conditions are defined below; please read them carefully
                  before signing the contract form.
                </p>
                <br />
                <h2>Requirements:</h2>
                <ul>
                  <li>
                    {" "}
                    Passport must be valid for at least 6 months from the date
                    of travel.
                  </li>
                  <li>Passport must have two (02) facing blank pages.</li>
                  <li>
                    Non-digital (Handwritten) passports will not be accepted.
                  </li>
                  <li>
                    For (Non-USA) passport holders, we can only apply for a Hajj
                    visa if the applicant is a resident in the United States. In
                    such cases, proof of residency will be required when
                    applying for the visa.
                  </li>
                  <li>Meningitis vaccination certificates</li>
                </ul>
                <p>
                  Note: We will retain your passports until visas have been
                  issued, which could take until close to the departure date.
                </p>
                <h2>Prices:</h2>
                <p>
                  The company reserves the right to change the package price at
                  any time without prior notice, and you will be informed
                  accordingly. The quoted price of an airline ticket may be
                  increased if the airline imposes any surcharge, and if tickets
                  are not issued within the given time limit. The price may also
                  be increased if the government imposes any charges pertaining
                  to your travel. The package price may also be increased as a
                  result of changes in prices by our suppliers in Saudi Arabia
                  (hotels, transport, etc.).
                </p>
                <br />
                <h2>Health and General Information:</h2>
                <p>It is your responsibility:</p>
                <p>
                  – To check your tickets, vaccination certificates,
                  accommodation vouchers, passport, visa, or anything else that
                  might prevent you from being allowed to travel. You must
                  notify the company immediately if you think any information is
                  incorrect. ZamZam Hajj will not be responsible for any
                  problems that may arise if you fail to notify the Company.
                </p>
                <p>
                  {" "}
                  – To make sure that your passport is valid for at least six
                  months after the date your pilgrimage ends.
                </p>
                <p>
                  {" "}
                  – To consider taking out travel insurance for your trip.
                  ZamZam Hajj would recommend this in all circumstances.
                </p>
                <p>
                  {" "}
                  – To ensure that you comply with all travel health
                  requirements, such as vaccination.
                </p>
                <p>
                  {" "}
                  – To ensure that you understand and are aware of the rituals
                  of your Umrah/Hajj and how to perform them.
                </p>
                <p>
                  {" "}
                  – To be aware that during the peak season, particularly the
                  Hajj period, because of the huge numbers of people on
                  pilgrimages and the problems of overcrowding, your room
                  facilities or furniture may be different. The company is
                  unable to prevent this from occurring occasionally and accepts
                  no liability for it.
                </p>
                <p>
                  {" "}
                  – To be aware that, particularly at the time of the Hajj,
                  flights and destination airports can sometimes be altered at
                  the last minute. The company has no control over this, and you
                  should accept that this could happen to you.
                </p>
                <p>
                  {" "}
                  -We take no responsibility for any changes that take place in
                  KSA; therefore, we strongly suggest you take Travel Insurance
                  before your departure for a safe and secure return journey.
                </p>
                <h2>Force Majeure:</h2>
                <p>
                  ZamZam Hajj shall not be held liable for any failure towards
                  the booking if this arises out of force majeure. This includes
                  any wars, hijacking, natural disasters, accidents, political
                  instability, lockdowns that affect the confirmed booking.
                  Please stay updated on the Covid-19 situation, its protocols,
                  and its effects on your scheduled flights. The company won't
                  take responsibility should you miss your flights due to
                  anything concerning the Covid-19 regulations announced by the
                  relevant authorities.
                </p>
                <h2>Payment Terms:</h2>
                <p>
                  Full payment for all Flight Packages is required at the time
                  of booking. Please note that additional charges may be payable
                  for car rental (e.g., optional insurance, additional drivers,
                  mileage) as further set out in the specific car rental terms
                  and conditions. The timing and collection of payment for
                  flights each individual terms and conditions. The timing of
                  payment will normally be in one of three ways: (i) full
                  payment at the time of booking, (ii) deposit at the time of
                  booking with the remainder payable on checkout, or (iii) full
                  payment on checkout. In order to ascertain which applies to
                  your booking, please refer to the ‘Payment Summary’ when
                  making your booking or see your verification e-mail. The
                  collector of payment will either be ZamZam Hajj, the Travel
                  Supplier, or its representative. Some airlines may charge
                  additional local taxes.{" "}
                </p>
                <p>
                  ZamZam Hajj may be required to pass your card details to the
                  relevant Travel Supplier for fulfillment of the booking.
                  Payment methods other than those stated on the Site will not
                  be accepted, and no responsibility is accepted for cash or
                  cheques sent through the post. Before payment is received in
                  full, ZamZam Hajj or the Travel Supplier is not obliged to
                  issue any tickets, confirmations, vouchers, or other travel
                  documents. However, you shall in all cases remain liable for
                  payment of the amounts agreed for the travel products and
                  services ordered. ZamZam Hajj is an IATA Ticketing Agent –
                  your tickets for scheduled flights will be sent to you within
                  24 hours of payment being accepted. Payment can be made by all
                  major debit and credit cards as detailed on the Site. ZamZam
                  Hajj or the Travel Supplier reserves the right only to deliver
                  tickets, confirmations, e-vouchers, or other travel documents
                  to your credit card billing address when requested to do so by
                  the credit card issuer. Failure to supply the correct credit
                  or debit card billing address information may result in the
                  cancellation of your booking, delays in the issue of your
                  tickets, and may make the fare(s) subject to increase.{" "}
                </p>
                <p>
                  Please ensure that the billing address details you give match
                  those on your billing statement. Further, in an effort to
                  minimize the effects of credit card fraud, we reserve the
                  right to carry out random checks, including validating name,
                  address, and other personal information supplied by you during
                  the order process against appropriate third party databases.
                  By accepting these terms and conditions, you consent to such
                  checks being made. In performing these checks personal
                  information provided by you may be disclosed to a registered
                  Credit Reference Agency which may keep a record of that
                  information. You can rest assured that this is done only to
                  verify your identity, that a credit check is not performed and
                  that your credit rating will be unaffected. All information
                  provided by you will be treated securely and strictly in
                  accordance with the Data Protection Act 1998.{" "}
                </p>
                <p>
                  In some cases, we may request you to either fax or post to us
                  proof of your address and a copy of the credit card and recent
                  statement before issuing any tickets. Refunds will be
                  processed to the form of payment used at the time of booking.
                  This will be made payable to the person who made the original
                  payment. Taxes fluctuate in line with exchange rates. If you
                  pay by credit card, payment for your flights may be taken
                  directly by the airline. If there are any problems with your
                  payment, we will contact you within 48 hours of your booking
                  (or within 24 hours if you are traveling within 48 hours).
                  ZamZam Hajj will not be liable for any subsequent price
                  increase as a result of payment failure. Any price increase
                  must be paid for by you before the booking can be confirmed.{" "}
                </p>
                <p>
                  Please note that the airline payment and payment to ZamZam
                  Hajj for any other products or ZamZam Hajj charges will appear
                  as separate transactions on your credit card statement.
                </p>
                <h2>Other Generally Applicable Terms:</h2>
                <p>
                  ZamZam Hajj assumes no legal responsibility unless the failure
                  to fulfill part or all of your booking, or any incidents of
                  death or personal injury, are directly caused by us, our
                  agents, or Travel Suppliers. For international travel by air,
                  sea, and rail, our liability will be constrained as per
                  relevant international conventions. In cases where ZamZam Hajj
                  is deemed legally responsible for direct loss, such liability
                  will be capped at the total price of the products or services
                  for which a claim is filed, excluding cases of death or
                  personal injury which have no such limit. ZamZam Hajj
                  disclaims any responsibility for indirect or consequential
                  losses arising from your use of this Site or any travel
                  products or services booked or purchased through it.
                </p>
                <p>
                  Considerations for Hajj: If you opt for the Shifting Hajj
                  package AZIZIYAH: Ensure that both Aziziah and Sheesha
                  buildings are of standard quality. These economic standard
                  buildings lack star rankings, regardless of whether your Hajj
                  package is rated 5 stars. Be cautious if your package includes
                  a stay in Aziziah or Sheesha buildings, as their standards
                  should be taken into account. As stated earlier, even if you
                  book a double/triple/quad sharing room, Aziziyah rooms will
                  typically accommodate 5 to 6 individuals. Once transferred to
                  Makkah or Medina hotels per your itinerary, room sharing will
                  adhere to your paid arrangement. Considering Aziziyah's
                  standards, be mindful that the rush during Hajj may lead to
                  issues with toilets or cleanliness. For problems encountered,
                  contact your group leader for immediate resolution
                </p>
                <p>
                  MINA: Accommodations in Mina usually involve European tents
                  equipped with air coolers and sofa beds. Note that a tent may
                  house 40 to 60 individuals. The walking distance to Jamarat
                  from European tents is approximately 45-60 minutes, depending
                  on your pace.
                </p>
                <h2>Hajj Bookings Guidelines:</h2>
                <p>
                  1. Please note that any details or itineraries provided to you
                  are considered "Proposed" and may be subject to change based
                  on availability or confirmation.
                </p>
                <p>
                  2. Changes in dates, up to +/- 2 or 3 days (outbound and
                  inbound), are expected and contingent upon the confirmation of
                  airlines' blocks for Hajj and supplier confirmations. ZamZam
                  Hajj will not assume responsibility for such changes.
                </p>
                <p>
                  3. Modifications in dates, airlines, airports, hotels, and
                  duration of stay are all dependent on availability and
                  confirmation. The company will not be held liable for any
                  resulting losses, whether financial or non-financial.
                </p>
                <p>
                  4. Hajj is a journey requiring resilience and patience. We
                  anticipate your cooperation for a smooth and trouble-free Hajj
                  trip.
                </p>
                <p>
                  5. The submission and collection of passports for the Hajj
                  visa process are the sole responsibility of the customer.
                  However, passports can be dispatched via Special Delivery
                  within 24 hours upon request, provided all dues are clear.
                </p>
                <p>
                  6. The total payment must be made before the 15th of Ramadan.
                  The company reserves the right to cancel the package if the
                  deadline is not met for any reason.
                </p>
                <p>
                  7. Cancellations made by the customer for a Hajj package are
                  non-refundable.
                </p>
                <p>
                  8. Due to the significant number of pilgrims in a confined
                  area, expect overwhelming crowds and potential delays due to
                  traffic congestion when waiting for buses or moving between
                  locations.
                </p>
                <p>
                  9. As a third-party agent for Hajj packages, we will provide
                  customers with relevant information about the organizers or
                  leaders at least a few days before departure.
                </p>
                <h2>Queries and Complaints:</h2>
                <p>
                  Please contact our customer services team for any inquiries or
                  complaints before your departure. If you have a complaint
                  related to a Travel Supplier or hotel during your stay, log
                  the complaint with the relevant Travel Supplier before your
                  return. For inquiries or complaints about your booking during
                  your stay, contact our customer services team upon your
                  return, providing your booking reference and all relevant
                  information. If additional information is required by us or
                  our Travel Suppliers, kindly supply it promptly in writing.
                  This process is essential for scrutinizing and, if possible,
                  rectifying the issue efficiently.
                </p>
                <h2>APIS Terms:</h2>
                <p>
                  Every airline is required to collect Advance Passenger
                  Information (API) from passengers traveling to or from the USA
                  and certain other countries. When you make a booking with
                  zamzamhajj.com, you agree to provide this information and
                  grant consent to zamzamhajj.com to share it with the airlines,
                  which may subsequently disclose it to foreign authorities.
                  Failure to provide Advance Passenger Information at the time
                  of reservation may result in entry refusal to these countries.
                  Several airlines, such as US Airways, American Airlines, and
                  many US carriers, strictly mandate the submission of complete
                  and valid passport information before ticket issuance.
                  Accuracy is paramount to avoid delays during Immigration
                  procedures upon arrival in these countries. The information
                  requested may vary depending on the destination country but
                  typically includes passport details, city and country of
                  residence, and destination address for travelers heading to
                  the USA. If you are traveling to a country requiring Advance
                  Passenger Information, zamzamhajj.com will request this
                  information after completing your reservation, and it is the
                  passenger's responsibility to promptly provide the requested
                  details. Failure to do so may result in cancellation charges,
                  in accordance with the airline's fare rules.
                </p>
                <h2>Customer Behavior Terms:</h2>
                <p>
                  It is your responsibility to ensure that your behavior is
                  appropriate and does not cause offense, danger to others, or
                  damage to property during your holiday or while using a
                  service/product. Inappropriate behavior, including but not
                  limited to drunkenness and air rage, may lead to the
                  cancellation of your booking by us and/or our Travel Suppliers
                  (e.g., airline staff). In such cases, our and our Travel
                  Supplier’s responsibilities to you will cease immediately, and
                  you will not be eligible for any refunds, compensation, or
                  reimbursement of costs or expenses resulting from such
                  termination. Additionally, you will be liable to reimburse us
                  for any expenses incurred due to such termination.
                </p>
                <p>
                  Terms and Conditions for Site Usage: The Site is provided on
                  an 'as is' and 'as available' basis. ZamZam Hajj assumes no
                  liability regarding your ability to access or use the site at
                  any time, or for any interruptions that may occur, leading to
                  the failure to complete any transaction. ZamZam Hajj does not
                  guarantee that the Site is free from computer viruses or other
                  properties that may cause loss or damage.
                </p>
                <h2>Information on the Site:</h2>
                <p>
                  ZamZam Hajj presents general information on the Site for
                  directional purposes only. Please be aware that ZamZam Hajj
                  may modify any aspect of the Site or its content, including
                  the accessibility of suppliers, features, information,
                  databases, or content at any time. It is your responsibility
                  to check with the relevant Travel Supplier, destination,
                  embassy, or tourist office to confirm that the guidance is up
                  to date. Particularly concerning passports, visas, and
                  vaccination requirements, ZamZam Hajj does not ensure that the
                  information is always current, and it is your responsibility
                  to understand and comply with all relevant passport, visa, and
                  vaccination requirements. While ZamZam Hajj does not guarantee
                  that information on the Site (including, but not limited to,
                  prices, descriptions, or dates) is free from errors or
                  omissions, it will make reasonable efforts to correct any
                  identified errors or omissions promptly. For instance, most
                  hotel and accommodation descriptions are provided directly to
                  our Site by the Travel Supplier or the hotels themselves.
                </p>
                <h2>User Obligations Terms:</h2>
                <p>
                  You agree to adhere to the following obligations, including
                  but not limited to: Assuming financial responsibility for all
                  transactions made under your name or account. Confirming that
                  you are 18 years of age or over and possess legal capacity.
                  Warranting that all information provided about yourself or
                  members of your household is true and accurate. Ensuring that
                  the Site is not used for speculative, false, or fraudulent
                  bookings. Expressly prohibiting the transmission of
                  threatening, defamatory, pornographic, political, or racist
                  material or any material that is otherwise unlawful.
                  Recognizing that the Site and its content may not be modified,
                  copied, transmitted, distributed, sold, displayed, licensed,
                  or reproduced in any way by you, except for the creation of
                  one copy of the information for personal, non-commercial use.
                </p>
                <h2>Denial of Access Terms:</h2>
                <p>
                  ZamZam Hajj retains the right to deny access to the Site
                  without notice at any time.
                </p>
                <h2>Links to Third-Party Websites:</h2>
                <p>
                  The Site may include hyperlinks to external websites owned and
                  operated by third parties. ZamZam Hajj has no control over or
                  affiliation with such third-party sites and assumes no
                  responsibility for the accuracy, completeness, and quality of
                  the information contained within them. Content on these
                  external websites does not reflect products, services, or
                  information provided by ZamZam Hajj. Any concerns regarding
                  external links should be directed to the site administrator or
                  webmaster of the respective site.
                </p>
                <h2>Copyright and Trademarks:</h2>
                <p>
                  Copyright and all proprietary rights in the Site and its
                  content are reserved by ZamZam Hajj. The material on the Site
                  is the property of ZamZam Hajj or its affiliates unless
                  identified as belonging to third parties. The names ZamZam
                  Hajj and any other marks, logos, and graphics presented on the
                  Site are registered trademarks of ZamZam Hajj or its
                  affiliates. Other company, product, or service names visible
                  on the Site may be the trademarks of their respective owners.
                  No right or license to use any trademarks is granted to you.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
        <FooterSection />
      </div>
    </div>
  );
};

export default TermsCondition;
