import React, { Component } from "react";

import CallRoundedIcon from "@material-ui/icons/CallRounded";
import Link from "@material-ui/core/Link";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";

import "../assets/css/style.css";

class UmrahPackage extends Component {
  render() {
    return (
      <div id="umrah" className="UmrahPackageContainer SectionContainer">
        <h1 className="sectionTitle TextalignCenter titleBorder textDark">
          <span className="borderLeft"></span>UMRAH PACKAGES{" "}
          <span className="borderRight"></span>
        </h1>
        <div className="subtitle TextalignCenter">
          Year-round individual and family custom Umrah packages available.
          Reach out to us for your own custom Umrah package as convenient for
          you.
        </div>

        <div className="contactInfoContainer">
          <div className="contactInfoBox">
            <CallRoundedIcon />
            <div className="contactInfoBoxTitle">Give us a call</div>
            <div className="contactInfoBoxSubTitle">+1 (917) 540-7944</div>

            <div className="contactInfoBoxSubLink">
              <a href="/#contact-us">See more local numbers</a>
            </div>
          </div>

          <div className="contactInfoBox">
            <MailOutlineRoundedIcon />
            <div className="contactInfoBoxTitle">Email us at</div>
            <div className="contactInfoBoxSubTitle">yeszamzam@gmail.com</div>
            <div className="contactInfoBoxSubTitle">team@zamzamhajj.com</div>
          </div>
        </div>
      </div>
    );
  }
}

export default UmrahPackage;
