import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SubscribeIocn from '../assets/imgs/subscribe.png'

import '../assets/css/style.css'




class SubscribeModal extends Component {

    render() {

        return (
            <div className="subsCribeModalContainer SectionContainer">
                <img className="SubscribeIocn" src={SubscribeIocn} />
               <h1>Subscribe to our newsletter</h1>
               <p>Subscribe now to get the latest news and updates</p>
               <div className="subsCribeForm">
                    <TextField className="SubscribeField" id="outlined-basic" label="Enter your email address" variant="outlined" />
                    <Button className="ButtonFIlledGreen" variant="contained" color="primary"> Subscribe Now</Button>
               </div>
               <div className="subsCribeFormBootmText">By submitting this form, I confirm I’ve read and agree to ZamZam’s <a href="#">Privacy Policy</a></div>

            </div>
        );
    }
}


export default SubscribeModal;