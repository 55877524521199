import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CheckmarkIcons from '../assets/imgs/CheckmarkIcons.svg'

import '../assets/css/style.css'




class ThanksSubscribeModal extends Component {

    render() {

        return (
            <div className="subsCribeModalContainer SectionContainer">
                <img className="CheckmarkIcons" src={CheckmarkIcons} />
               <h1>Thank you for subscribing</h1>
               <p>We will send goodness every week.</p>
               <Button className="ButtonFIlledGreen top_space" variant="contained" color="primary"> Subscribe Now</Button>
            </div>
        );
    }
}


export default ThanksSubscribeModal;