import React from "react";
import { TitleOne } from "master-react-lib";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

function SectionTitle(props) {
  const onClick = () => {
    console.log("Back Button Clicked");
  };

  return (
    <TitleOne
      TitleLight={props.TitleLight}
      TitleBold={props.TitleBold}
      TitleLightEnd={props.TitleLightEnd}
      lineColor="#28b356"
      TitleBoldColor="#28b356"
      Button={props.ButtonStatus}
      buttonLebel={props.buttonLebel}
      Link={props.Link}
      target={props.target}
      onClick={onClick}
      arrowIcon={<ArrowForwardIosRoundedIcon />}
      arrowStatus={true}
      arrowColor="#28b356"
      width="auto"
      height="50px"
      border="#28b356 1px solid"
      borderRadius="4px"
      colorlabel="#28b356"
      fontWeight="600"
      textAlign="center"
    />
  );
}

export default SectionTitle;
