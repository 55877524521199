import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import '../assets/css/style.css'




class Subscribe extends Component {

    render() {

        return (
            <div className="subsCribeContainer SectionContainer">

               <h1 className="subsCribeTitle">Subscribe to get the latest updates from ZamZam team</h1>
               
               <div className="subsCribeForm">
                    <TextField id="outlined-basic" label="Enter your email address" variant="outlined" />
                    <Button className="ButtonFIlledGreen" variant="contained" color="primary"> Subscribe now</Button>
               </div>

            </div>
        );
    }
}


export default Subscribe;