import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import FacebookIcon from '../assets/imgs/socialicons/FacebookRounded.svg';
import TwitterIcon from '../assets/imgs/socialicons/TwitterRounded.svg';
import WhatsAppIcon from '../assets/imgs/socialicons/WhatsappRounded.svg';
import YouTubeIcon from '../assets/imgs/socialicons/YouTubeRounded.svg';

import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Location from '../assets/imgs/eventicons/location.svg';
import Zoom from '../assets/imgs/eventicons/zoom.svg';
import Facebook from '../assets/imgs/eventicons/facebook.svg';
import Youtube from '../assets/imgs/eventicons/youtube.svg';
import Phone from '../assets/imgs/eventicons/phone.svg';

import ShareRoundedIcon from '@material-ui/icons/ShareRounded';


import EventFlyer from '../assets/imgs/events/ZamZam-Hajj-Kafela_2022.webp';

import DelwarHossainone from '../assets/imgs/ZamZam_Team_Imam_Delwar_Hossain.jpeg';
import HafizRayhanUddin from '../assets/imgs/ZamZam_Team_Hafiz_Rayhan_Uddin.jpeg';
import ShaikhIsmailAlMakki from '../assets/imgs/ZamZam_Team_Shaikh_Ismail_Al-Makki.jpg';



class EventDetailsModalOne extends Component {

    render() {

        return (
            <div className="EventDetailsModalContainer">


                <Grid container spacing={0}>

                    <Grid item md={5} sm={5} xs={12}>
                        <div className="ImgCol">
                            <img className="dtlImg" src={EventFlyer} />
                            <div className="ShareCol">
                                <div className="SocialIcon">
                                    <Link href="https://www.facebook.com/ZamzamHajjUSA/" target='_blank'><img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    <Link href="https://www.youtube.com/channel/UCfe06KuigZlc3wtYpXJNRHw" target='_blank'><img src={YouTubeIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    {/* <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link> */}
                                    {/* <Link className="ShareIcon">Share | <ShareRoundedIcon /></Link> */}
                                </div>
                            </div>
                        </div>

                        <div className="DtlContentLeft">
                            <h2>Event Description</h2>
                            <p>When the pilgrim is about 6 miles (10 km) from Mecca, he or she enters the state of holiness and purity known as
                                ihram and dons the ihram garments; for men they consist of two white seamless sheets that are wrapped
                                around the body, while women may wear sewn clothes. The pilgrims cut neither their hair nor their nails until the
                                pilgrimage rite is over...</p>
                        </div>
                    </Grid>

                    <Grid item md={7} sm={7} xs={12}>
                        <div className="DtlRightContainer">
                            <div className="DtlDate">June 23/24 - July 15/16</div>
                            <h1>Hajj Kafela 2022</h1>

                            {/* <div className="DtlStatusCol">
                                <li><AccessTimeRoundedIcon /> Hajj Kafela 2022</li>
                                <li><VideocamRoundedIcon /> Online</li>
                                <li><AttachMoneyRoundedIcon /> Free</li>
                                <li><GroupRoundedIcon /> Anyone can join</li>
                            </div> */}

                            {/* <div className="SpeekerContainer">
                                <h1>Speakers</h1>

                                <Grid container spacing={1}>

                                    <Grid item md={12} sm={12} xs={12}>
                                        <div className="Speekerchip">
                                            <div className="SpeekerIcon">
                                                <img src={DelwarHossainone} />
                                            </div>
                                            <div className="SpeekerDetails">
                                                <h2>Imam Dalouer Hossain</h2>
                                                <p>Ameer-ZamZam Hajj Kafela</p>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={12} sm={12} xs={12}>
                                        <div className="Speekerchip">
                                            <div className="SpeekerIcon">
                                                <img src={HafizRayhanUddin} />
                                            </div>
                                            <div className="SpeekerDetails">
                                                <h2>Hafiz Raihan Uddin</h2>
                                                <p>Imam, Masjid Al-Falah, Detroit, MI</p>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={12} sm={12} xs={12}>
                                        <div className="Speekerchip">
                                            <div className="SpeekerIcon">
                                                <img src={ShaikhIsmailAlMakki} />
                                            </div>
                                            <div className="SpeekerDetails">
                                                <h2>Shaikh Ismail Makki</h2>
                                                <p>PhD Researcher - Umm Al-Qura University,Saudi Arabia</p>
                                            </div>
                                        </div>
                                    </Grid>

                                </Grid>


                            </div> */}


                            <div className="LocationContainer">
                                {/* <h1>Event Location</h1> */}

                                <div className="accordionContainer">

                                    {/* <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content" id="panel1a-header" >
                                            <Typography className="accordionTitle"><img src={Location} /> Location/Venue</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Lorem ipsum dolor sit amet, consectetur
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion> */}

                                    {/* <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content" id="panel1a-header" >
                                            <Typography className="accordionTitle"><img src={Zoom} /> Zoom</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Link: <a href="https://us02web.zoom.us/j/86886474449?pwd=d2VXVU9DT214YzVqcTRLei8vSnUvdz09" target='_blank'>https://us02web.zoom.us/j/86886474449?pwd=d2VXVU9DT214YzVqcTRLei8vSnUvdz09</a>
                                            <p>Topic: The Hajj Pilgrimage and Its Significance in Islam</p>
                                            <p>Topic: The Hajj Pilgrimage and Its Significance in Islam</p>
                                            <p>Time: Jan 22, 2022 02:00 PM Astana, Dhaka </p>
                                            <p>Meeting ID: 780 1280 8408 </p>
                                            <p>Passcode: Sx2DvH</p>
                                        </AccordionDetails>
                                    </Accordion> */}

                                    {/* <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content" id="panel1a-header" >
                                            <Typography className="accordionTitle"><img src={Facebook} /> Facebook</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Lorem ipsum dolor sit amet, consectetur
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion> */}

                                    {/* <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content" id="panel1a-header" >
                                            <Typography className="accordionTitle"><img src={Youtube} /> YouTube</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Lorem ipsum dolor sit amet, consectetur
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion> */}

                                    {/* <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content" id="panel1a-header" >
                                            <Typography className="accordionTitle"><img src={Phone} /> Teleconference</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Lorem ipsum dolor sit amet, consectetur
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion> */}


                                </div>

                            </div>

                        </div>
                    </Grid>

                </Grid>
            </div>
        );
    }
}


export default EventDetailsModalOne;