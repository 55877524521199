import React from "react";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import Grid from "@material-ui/core/Grid";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

function UmrahSep(props) {
  const classes = useStyles();

  const packgesList = [
    { title: "COMMON SERVICES FOR ALL GROUPS" },

    { subheadings: "Round trip >JFK-Jeddah-Medina-JFK" },
    { bold: "Visa, hotels and transportation" },
    { bold: "Hotels very close to Haramain, airport pickup & dropoff" },
    { bold: "Historical place Ziyara(tour) in Makkah & Medina" },
    { subheadings: "Open bufett breakfast" },
    {
      subheadings:
        "Ziyara in Makkah & Medina ZamZam water before departure (Upon Authorities)",
    },
    { subheadings: "For Bangladeshi passport, add +$250 more" },
    { bold: "Anyone within USA can join inshallah. Call for details." },

    { title: "REQUIREMENTS" },
    { subheadings: "6 months valid passport" },
    { subheadings: "1(one) passport size color photo" },
    // { subheadings: "Fully vaccinated for Covid-19 with Booster Dose" },
    // { subheadings: "Meningitis shot (Meningococcal vaccine)" },

    { title: "HOTELS" },
    { bold: "Makkah Hotel (Former Hilton Hotel)" },
    { bold: "Frontal Al-Harithia Hotel (Close to Masjid An-Nabawi)" },
  ];

  return (
    <div className="UmrahDetailsSection">
      {packgesList.map((item, i) => (
        <div className="UmrahPackageList">
          <span>
            {" "}
            <CheckCircleOutlineRoundedIcon />
            {item.subheadings}
          </span>

          <span>
            {" "}
            <h2>{item.title}</h2>
            <strong>{item.bold}</strong>
          </span>
        </div>
      ))}

      <div className={classes.root}>
        <Alert severity="error">
          To reserve your seat a $500 deposit is REQUIRED{" "}
        </Alert>
      </div>

      <div className="UmrahPackageCardContaner">
        <Grid container spacing={3}>
          <Grid item md={4} sm={12} xs={12}>
            <div className="UmrahPackageCard CrdBlue">
              <h1>QUAD</h1>
              <h2>
                $2590 <span>per person</span>
              </h2>
              <div className="CdrListing">
                <li>
                  <FiberManualRecordRoundedIcon /> 4 people per room
                </li>
                <li>
                  <FiberManualRecordRoundedIcon /> Price includes all taxes
                </li>
                <li>
                  <FiberManualRecordRoundedIcon /> Only for families
                </li>
              </div>
              <Button
                className="CarBtn"
                href="https://www.facebook.com/ZamzamHajjUSA/"
                target="_blank"
              >
                BOOK NOW <ArrowForwardIosRoundedIcon />
              </Button>
            </div>
          </Grid>

          <Grid item md={4} sm={12} xs={12}>
            <div className="UmrahPackageCard CrdGreen">
              <h1>TRIPLE</h1>
              <h2>
                +$2690 <span>per person</span>
              </h2>
              <div className="CdrListing">
                <li>
                  <FiberManualRecordRoundedIcon /> 3 people per room
                </li>
                <li>
                  <FiberManualRecordRoundedIcon /> Price includes all taxes
                </li>
                <li>
                  <FiberManualRecordRoundedIcon /> Only for families
                </li>
              </div>
              <Button
                className="CarBtn"
                href="https://www.facebook.com/ZamzamHajjUSA/"
                target="_blank"
              >
                BOOK NOW <ArrowForwardIosRoundedIcon />
              </Button>
            </div>
          </Grid>

          <Grid item md={4} sm={12} xs={12}>
            <div className="UmrahPackageCard CrdPurple">
              <h1>DOUBLE</h1>
              <h2>
                $2890 <span>per person</span>
              </h2>
              <div className="CdrListing">
                <li>
                  <FiberManualRecordRoundedIcon /> 2 people per room
                </li>
                <li>
                  <FiberManualRecordRoundedIcon /> Price includes all taxes
                </li>
              </div>
              <Button
                className="CarBtn"
                href="https://www.facebook.com/ZamzamHajjUSA/"
                target="_blank"
              >
                BOOK NOW <ArrowForwardIosRoundedIcon />
              </Button>
            </div>
          </Grid>

          {/* <Grid item md={3} sm={12} xs={12}>
                        <div className='UmrahPackageCard CrdBlue'>
                            <h1>SINGLE</h1>
                            <h2>+$3790 <span>per person</span></h2>
                            <div className='CdrListing'>
                                <li><FiberManualRecordRoundedIcon /> 1 people per room</li>
                                <li><FiberManualRecordRoundedIcon /> Price includes all taxes</li>
                            </div>
                            <Button className='CarBtn' href="https://www.facebook.com/ZamzamHajjUSA/">
                                BOOK NOW <ArrowForwardIosRoundedIcon />
                            </Button>
                        </div>
                    </Grid> */}
        </Grid>
      </div>
    </div>
  );
}

export default UmrahSep;
