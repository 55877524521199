import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Lightbox from "react-image-lightbox";
import ViewGallery from "./vg";

import Book_5 from "../assets/imgs/Books/Anugotto_Poromarsha_Ihtisab_ImamDelwarHossain.png";
import Book_2 from "../assets/imgs/Books/Hadiser_Aloke_ImamDelwarHossain.png";
import Book_3 from "../assets/imgs/Books/Manob_Jibone_Quran_Hadith_ImamDelwarHossain.png";
import Book_1 from "../assets/imgs/Books/Salam_ImamDelwarHossain.png";
import Book_4 from "../assets/imgs/Books/Takwa_O_Muttaqi_ImamDelwarHossain.png";

function Book(props) {
  const images = [Book_1, Book_2, Book_3, Book_4, Book_5];
  const titleText = "Books by Imam Dalouer";
  const descriptionText = (
    <div>
      <p>
        Globally sold and researched books with authentic reference to Quran &
        Hadith.
      </p>
      <p style={{ height: "15px" }}></p>
      <p>For purchase/order inquiries,</p>
      <p>
        please call{" "}
        <a className="CellNumber" href="tel: +1 (917) 540-7944">
          +1 (917) 540-7944
        </a>
      </p>
    </div>
  );
  const buttonText = "See more books";

  const [isOpen, setisOpen] = useState(false);

  const [photoIndex, setphotoIndex] = useState(0);

  const imageClick = (i) => {
    setphotoIndex(i);
    setisOpen(true);
  };

  return (
    <div>
      <ViewGallery
        images={images}
        titleText={titleText}
        descriptionText={descriptionText}
        buttonText={buttonText}
        buttonClicked={(e) => imageClick(0)}
        GalleryGrid={
          <div className="PhotoGalleryContainer">
            <Grid container spacing={1}>
              <Grid item md={4} xs={12}></Grid>

              <Grid item md={4} xs={12}>
                <div className="GelleryImgContaier">
                  <img
                    className="bookImg"
                    src={images[0]}
                    onClick={() => imageClick(0)}
                  />
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="GelleryImgContaier">
                  <img
                    className="bookImg"
                    src={images[1]}
                    onClick={() => imageClick(1)}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item md={4} xs={12}>
                <div className="GelleryImgContaier">
                  <img
                    className="bookImg"
                    src={images[2]}
                    onClick={() => imageClick(2)}
                  />
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="GelleryImgContaier">
                  <img
                    className="bookImg"
                    src={images[3]}
                    onClick={() => imageClick(3)}
                  />
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div className="GelleryImgContaier">
                  <img
                    className="bookImg"
                    src={images[4]}
                    onClick={() => imageClick(4)}
                  />
                </div>
              </Grid>
            </Grid>

            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={() => setisOpen(false)}
                onMovePrevRequest={() =>
                  setphotoIndex(
                    (photoIndex + images.length - 1) % images.length
                  )
                }
                onMoveNextRequest={() =>
                  setphotoIndex((photoIndex + 1) % images.length)
                }
                animationDisabled={false}
                animationOnKeyInput={true}
              />
            )}
          </div>
        }
      />
    </div>
  );
}

export default Book;
