import React from "react";
import {
  PageTitleOne,
  TextInputFixedLabel,
  PhoneInputField,
  ButtonOne,
} from "master-react-lib";
import HeaderSection from "../Common/HeaderSection";
import FooterSection from "../Common/FooterSection";
import backgroundPattern from "../../assets/imgs/Pattern.svg";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import Button from "@material-ui/core/Button";

function Registration(props) {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });

  const click = () => {
    console.log("Back Button Clicked");
  };

  return (
    <div className="MainContainer">
      <HeaderSection />

      <div className="innterContainer">
        <PageTitleOne
          Title="Hajj 2024 Pre-registration"
          SubTitle="ZamZam Hajj & Umrah"
          color="#fff"
          backgroundcolor="#56B149"
          backgroundPattern={backgroundPattern}
        />

        <div className="RgsFrmCtn">
          <h1>Attendee details</h1>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div className="field_box">
                <TextInputFixedLabel label="First name *" labelStatus={true} />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="field_box">
                <TextInputFixedLabel label="First name *" labelStatus={true} />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="field_box">
                <TextInputFixedLabel label="Last name *" labelStatus={true} />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="field_box">
                <TextInputFixedLabel label="Age *" labelStatus={true} />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="SelectFieldContainer">
                <FormControl>
                  <label class="input_label">Gender *</label>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Male</MenuItem>
                    <MenuItem value={20}>Female</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="SelectFieldContainer">
                <FormControl>
                  <label class="input_label">Hajj year *</label>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Male</MenuItem>
                    <MenuItem value={20}>Female</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="SelectFieldContainer">
                <FormControl>
                  <label class="input_label">Hajj package *</label>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Male</MenuItem>
                    <MenuItem value={20}>Female</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} md={8}>
              <div className="field_box">
                <TextInputFixedLabel
                  label="Mailing street address *"
                  labelStatus={true}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <div className="field_box">
                <TextInputFixedLabel
                  label="Apt/Floor (Optional)"
                  labelStatus={true}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div className="field_box">
                <TextInputFixedLabel label="City *" labelStatus={true} />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="SelectFieldContainer">
                <FormControl>
                  <label class="input_label">State</label>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>NY</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div className="field_box">
                <TextInputFixedLabel
                  label="Zip/Postal code *"
                  labelStatus={true}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="field_box">
                <PhoneInputField
                  phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                  country={"us"}
                  value={phone.number}
                  countryCodeEditable={false}
                  onChange={(phone, country) =>
                    console.log("onChange", phone, country)
                  }
                  onBlur={() => console.log("onBlur")}
                  onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                  variant="outlined"
                  // onlyCountries={["us", "ca", "gb", "my"]}
                  containerStyle={{}}
                  buttonStyle={{}}
                  inputStyle={{
                    marginLeft: "0px",
                    height: "40px",
                    width: "100%",
                    fontFamily: "Roboto-Regular",
                    fontSize: "16px",
                  }}
                  containerClass={{}}
                  errorText={"Phone number is required"}
                  error={false}
                  errorStyles={{ marginBottom: "5px" }}
                  autoFormat={true}
                  disableDropdown={false}
                  disabled={false}
                  disableCountryCode={false}
                  enableSearch={false}
                  disableSearchIcon={false}
                  placeholder={""}
                  inputProps={{}}
                  specialLabel={"Primary Phone"}
                  // preferredCountries={[]}
                  // excludeCountries={[]}
                  labelFixed="Phone number *"
                  LabelStatus={true}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="field_box">
                <TextInputFixedLabel
                  label="Email address *"
                  labelStatus={true}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={12}>
              <div className="field_box switchCtn">
                <div className="switchLabel">
                  Performing Hajj with family or friends
                </div>
                <FormControlLabel
                  value="top"
                  control={<Switch color="primary" />}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={12}>
              <div className="AttendeeForm">
                <div className="AttendeeFormHdr">
                  <h2>Attendee 2</h2>
                  <div className="AtdRightCol">
                    <Button href="#text-buttons" className="atnEdit">
                      Edit
                    </Button>
                    <Button href="#text-buttons" className="atnDelete">
                      Delete
                    </Button>
                  </div>
                </div>
                <div className="AttendeeFormDtl">
                  <h3>Shayan Mahmud</h3>
                  <p>Male, 26</p>
                  <p>Package Group A, Hajj year 2021</p>
                  <p>Siblings</p>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={12}>
              <div className="AttendeeForm">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <div className="field_box">
                      <TextInputFixedLabel
                        label="First name *"
                        labelStatus={true}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="field_box">
                      <TextInputFixedLabel
                        label="Last name *"
                        labelStatus={true}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="field_box">
                      <TextInputFixedLabel label="Age *" labelStatus={true} />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="SelectFieldContainer">
                      <FormControl>
                        <label class="input_label">Gender *</label>
                        <Select
                          labelId="demo-customized-select-label"
                          id="demo-customized-select"
                          value={age}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Male</MenuItem>
                          <MenuItem value={20}>Female</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="SelectFieldContainer">
                      <FormControl>
                        <label class="input_label">Hajj year *</label>
                        <Select
                          labelId="demo-customized-select-label"
                          id="demo-customized-select"
                          value={age}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Male</MenuItem>
                          <MenuItem value={20}>Female</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="SelectFieldContainer">
                      <FormControl>
                        <label class="input_label">Hajj package *</label>
                        <Select
                          labelId="demo-customized-select-label"
                          id="demo-customized-select"
                          value={age}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Male</MenuItem>
                          <MenuItem value={20}>Female</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} md={12}>
              <div className="AttendeeForm">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <div className="SelectFieldContainer">
                      <FormControl>
                        <label class="input_label">
                          Relationship to primary attendee
                        </label>
                        <Select
                          labelId="demo-customized-select-label"
                          id="demo-customized-select"
                          value={age}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Male</MenuItem>
                          <MenuItem value={20}>Female</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} md={12} style={{ marginTop: "16px" }}>
              <ButtonOne
                label="ADD MORE ATTENDEE"
                onClick={click}
                buttoncolor="#fff"
                width="100%"
                height="50px"
                border="#0590FA 1px solid"
                borderRadius="4px"
                color="#0590FA"
                fontWeight="600"
                textAlign="center"
                addIcon={true}
                AddIconColor="#0590FA"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <div className="field_box">
                <div className="switchLabel">
                  Have you performed Hajj before? *
                </div>
                <FormControlLabel
                  value="end"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="end"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </div>
            </Grid>

            <Grid item xs={12} md={12}>
              <div className="SelectFieldContainer">
                <FormControl>
                  <label class="input_label">How did you hear about us *</label>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>NY</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12} md={12}>
              <div className="InfoText">
                <li>
                  <InfoRoundedIcon />
                  <p>
                    Hajj package prices are not yet fixed due to confirmation
                    pending from “The Ministry of Hajj and Umrah”. If you have
                    any queries, please call{" "}
                    <span>
                      {" "}
                      Imam Dalouer at{" "}
                      <a className="CellNumber" href="tel: +1 (917) 540-7944">
                        +1 (917) 540-7944
                      </a>
                    </span>
                  </p>
                </li>
              </div>
            </Grid>

            <Grid item xs={12} md={12}>
              <ButtonOne
                label="SUBMIT"
                onClick={click}
                buttoncolor="#56B149"
                width="100%"
                height="50px"
                border="#ccc 0px solid"
                borderRadius="4px"
                color="#fff"
                fontWeight="600"
                textAlign="center"
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <FooterSection />
    </div>
  );
}

export default Registration;
