import React from "react";
import HeaderSection from "../../Common/HeaderSection";
import FooterSection from "../../Common/FooterSection";

const BlogLayout = ({ children }) => {
  return (
    <div className="MainContainer">
      <HeaderSection />
      <div className="innterContainer">{children}</div>
      <FooterSection />
    </div>
  );
};

export default BlogLayout;
