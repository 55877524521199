
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import '../assets/css/style.css'



class HajjGuides extends Component {

    render() {

        return (
            <div className="HajjGuidContainer SectionContainer">
               <h1 className="sectionTitle TextalignCenter textDark">Hajj GUIDES</h1>
               
                <Grid container spacing={3}>	      

                    <Grid item xs={4}> 
                        <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="https://d2doewlpstuw7k.cloudfront.net/ZamZam-HajjUmrah-Book-Bangla.pdf"  target="blank">Hajj Guide / হজ গাইড <ArrowForwardIosRoundedIcon/></Button>
                    </Grid>

                    {/* <Grid item xs={4}> 
                        <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="#outlined-buttons"  target="blank">Hajj Map / হজ মানচিত্র <ArrowForwardIosRoundedIcon/></Button>
                    </Grid> */}

                    <Grid item xs={4}> 
                        <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="https://d2doewlpstuw7k.cloudfront.net/ZamZam-Hajj-Dua-Pocket-Book.pdf" target="blank">HAJJ Du’a Book / BANGLA <ArrowForwardIosRoundedIcon/></Button>
                    </Grid>

                    {/* <Grid item xs={4}> 
                        <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="#videos">Videos / BANGLA/ENGLISH <ArrowForwardIosRoundedIcon/></Button>
                    </Grid>

                    <Grid item xs={4}> 
                        <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="#outlined-buttons"  target="blank">Checklist for Hajj / হজের চেকলিস্ট <ArrowForwardIosRoundedIcon/></Button>
                    </Grid> */}

                    <Grid item xs={4}> 
                        <Button className="HajjGuidContainerLinks" variant="outlined" color="primary" href="https://d2doewlpstuw7k.cloudfront.net/ZamZam_Registration Form_2023.pdf"  target="blank">DOWNLOAD REGISTRATION FORM<ArrowForwardIosRoundedIcon/></Button>
                    </Grid>

                </Grid>

            </div>
        );
    }
}


export default HajjGuides;