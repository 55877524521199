import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import styles from "../styles/Phone.css";
// import "../../styles/fonts.css"

let excludeCountriesArr = [
  "by",
  "mm",
  "bi",
  "cn",
  "cu",
  "ir",
  "lb",
  "ly",
  "kp",
  "so",
  "sd",
  "sy",
  "ua",
  "ve",
  "zw",
];

export function PhoneInputField(props) {
  const [stylePath, setstylePath] = useState("");

  useEffect(() => {
    if (props.phoneInputVariant === "materialui") {
      require("react-phone-input-2/lib/material.css");
    } else if (props.phoneInputVariant === "underlineWithFlag") {
      require("react-phone-input-2/lib/style.css");
    } else if (props.phoneInputVariant === "default") {
      require("react-phone-input-2/lib/style.css");
    }
  }, [props.phoneInputVariant]);

  return (
    <div className={props.error}>
      <div className="PhoneFieldManContainer">
        <div
          className={`phone-text-field_container ${
            props.phoneInputVariant ? props.phoneInputVariant : ""
          }`}
        >
          <div className={styles.FieldLabel}>
            {props.LabelStatus && (
              <label className="input_label">{props.labelFixed}</label>
            )}
            {props.error && <span>Required</span>}
          </div>

          <link rel="stylesheet" type="text/css" href={stylePath} />
          <PhoneInput
            className="phoneInputField"
            country={props.country}
            value={props.value}
            countryCodeEditable={props.countryCodeEditable}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
            variant={props.variant}
            onlyCountries={props.onlyCountries}
            containerStyle={props.containerStyle}
            buttonStyle={props.buttonStyle}
            inputStyle={props.inputStyle}
            containerClass={props.containerStyle}
            errorText={props.errorText}
            error={props.error}
            autoFormat={props.autoFormat}
            disableDropdown={props.disableDropdown}
            disabled={props.disabled}
            disableCountryCode={props.disableCountryCode}
            enableSearch={props.enableSearch}
            disableSearchIcon={props.disableSearchIcon}
            placeholder={props.placeholder}
            inputProps={props.inputProps}
            specialLabel={props.specialLabel}
            preferredCountries={props.preferredCountries}
            excludeCountries={
              props.excludeCountries?.length
                ? props.excludeCountries
                : excludeCountriesArr
            }
            showDropdown={false}
          />
          <div className="bottom_text_col">
            <span className="helper_text">{props.helpertext}</span>
          </div>
          {/* {props.error && (
          <span
            className="error"
            style={props.errorStyles}
          >
            {props.errorText}
          </span>
        )} */}
        </div>
      </div>
    </div>
  );
}

export default PhoneInputField;
