import React, { Component } from 'react';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import '../assets/css/style.css'




class CommonServices extends Component {

    render() {

        return (

            <div className="CommonServicesContainer">
                <div className="CommonServicesContainerInner GreenPattren SectionContainer">

                    <h1 className="CommonServicesTitle">Common services for Hajj 2023 packages</h1>

                    <Grid container spacing={3}>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p><span>Round trip</span> from JFK-Medina and Jeddah-JFK</p></div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p>Arafah - Tents, lunch, fruits and refreshments</p></div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p><span>Air-conditioned bus</span> from Medina to Makkah</p></div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p><span>Full access to your room</span> during Manasek</p></div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p><span>Both hotels</span> close to Haramain (Makkah & Medina)</p></div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p><span>Visit to historical sites </span>in Medina by local Shaykh</p></div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p>Medina 4 person and Makkah 5 person per room</p></div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p>Medina 4 person and Makkah 5 person per room</p></div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p><span>3 daily meals</span> - Makkah, Medina, Mina</p></div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p>Additional charge applies if departing city is not NY</p></div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p><span>Mina - Upgraded air-conditioned tents</span> with individual mattresses and blankets</p></div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className="CommonServicesListing"><CheckCircleOutlineRoundedIcon /> <p>Price doesn't include Hajj & Qurbani fees (Prices as per “The Ministry of Hajj and Umrah”)</p></div>
                        </Grid>

                        <div className="whiteCardContainer">
                            
                            <div className="whiteCard">
                                <h2>NO APARTMENTS</h2>
                                <p>Generally far and may need transportation to go for daily prayers at Haram</p>
                            </div>

                            <div className="whiteCard">
                                <h2>NO AZIZIYA</h2>
                                <p>3 miles from Haram. Too far for many to join for daily prayers.</p>
                            </div>


                            <div className="whiteCard">
                                <h2>NO APARTMENTS</h2>
                                <p>Generally far and may need transportation to go for daily prayers at Haram</p>
                            </div>


                        </div>

                    </Grid>

                </div>
            </div>
        );
    }
}


export default CommonServices;