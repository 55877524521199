import React, { Component } from "react";
import { PricingTable } from "master-react-lib";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
const tableHeadData = [
  {
    title: "GROUP A",
    theme: "blueTheme",
    link: "https://d2doewlpstuw7k.cloudfront.net/ZamZam_Registration Form_2023.pdf",
  },
  {
    title: "GROUP B",
    theme: "greenTheme",
    link: "https://d2doewlpstuw7k.cloudfront.net/ZamZam_Registration Form_2023.pdf",
  },
  // {title: "GROUP C", price: "9100", theme: "greyTheme"},
];

const tableBodyData = [
  { featureName: "Total # of days", col1: "21/22 Days", col2: "15/16 Days" },
  { featureName: "Departure from USA", col1: "June 23/24", col2: "June 28/29" },
  { featureName: "Arrival in USA", col1: "July 15/16", col2: "July 15/16" },
  {
    featureName: "Medinah - Hotel",
    col1: "Close to “Al Masjid an Nabawi”",
    col2: "Close to “Al Masjid an Nabawi”",
  },
  {
    featureName: "Ziyarat (Visit pious places)",
    col1: "checked",
    col2: "checked",
  },
  {
    featureName: "Makkah - Hotel",
    col1: "Makkah Tower ( Former Hilton Tower)",
    col2: "Makkah Tower ( Former Hilton Tower)",
  },
  { featureName: "Mina", col1: "AC Camp - USA", col2: "AC Camp - USA" },
];

class PricingTableSection extends Component {
  render() {
    return (
      <div id="hajj-packages" className="SectionContainer SectionGrey">
        <PricingTable
          title={"HAJJ 2023 PACKAGES"}
          subTitle={"TAX & VAT INCLUDED"}
          tableHeadData={tableHeadData}
          tableBodyData={tableBodyData}
          SelectlinkOne="https://d2doewlpstuw7k.cloudfront.net/ZamZam_Registration Form_2023.pdf"
          SelectlinkTwo="https://d2doewlpstuw7k.cloudfront.net/ZamZam_Registration Form_2023.pdf"
          Selectlinkthree="https://d2doewlpstuw7k.cloudfront.net/ZamZam_Registration Form_2023.pdf"
        />
        <div className="PrcTableBottomSec">
          <li>
            <InfoRoundedIcon />
            <p>
              Hajj package prices are not yet fixed due to confirmation pending
              from “The Ministry of Hajj and Umrah”
            </p>
          </li>

          <li>
            <InfoRoundedIcon />
            <p>
              {" "}
              Currently we are accepting pre-registration of $1000/per member{" "}
              <span>
                which will be adjusted from total Hajj fee. Pre-registration fee
                is 100% refundable.
              </span>
            </p>
          </li>
          <li>
            <InfoRoundedIcon />
            <p>
              If you have any queries, please call{" "}
              <span>
                Imam Dalouer at{" "}
                <a className="CellNumber" href="tel: +1 (917) 540-7944">
                  +1 (917) 540-7944
                </a>
              </span>
            </p>
          </li>
        </div>
      </div>
    );
  }
}

export default PricingTableSection;
