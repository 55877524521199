import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import FacebookIcon from '../assets/imgs/socialicons/FacebookRounded.svg';
import TwitterIcon from '../assets/imgs/socialicons/TwitterRounded.svg';
import WhatsAppIcon from '../assets/imgs/socialicons/WhatsappRounded.svg';
import Flayer from '../assets/imgs/events/ZamZam-Umrah-2022-Ramadhan.webp';

import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Location from '../assets/imgs/eventicons/location.svg';
import Zoom from '../assets/imgs/eventicons/zoom.svg';
import Facebook from '../assets/imgs/eventicons/facebook.svg';
import Youtube from '../assets/imgs/eventicons/youtube.svg';
import Phone from '../assets/imgs/eventicons/phone.svg';

import ShareRoundedIcon from '@material-ui/icons/ShareRounded';




class EventDetailsModalTwo extends Component {

    render() {

        return (
            <div className="EventDetailsModalContainer">


                <Grid container spacing={0}>

                    <Grid item md={5} sm={5} xs={12}>
                        <div className="ImgCol">
                            <img className="dtlImg" src={Flayer} />
                            <div className="ShareCol">
                                <div className="SocialIcon">
                                    <Link><img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    <Link><img src={TwitterIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill" /></Link>
                                    <Link className="ShareIcon">Share | <ShareRoundedIcon /></Link>
                                </div>
                            </div>
                        </div>

                        <div className="DtlContentLeft">
                            <h2>Event Description</h2>
                            <p>When the pilgrim is about 6 miles (10 km) from Mecca, he or she enters the state of holiness and purity known as
                            ihram and dons the ihram garments; for men they consist of two white seamless sheets that are wrapped
                            around the body, while women may wear sewn clothes. The pilgrims cut neither their hair nor their nails until the
                               pilgrimage rite is over... Continue Reading</p>
                        </div>
                    </Grid>

                    <Grid item md={7} sm={7} xs={12}>
                        <div className="DtlRightContainer">
                            <div className="DtlDate">MARCH 30/31 - APRIL 9/10</div>
                            <h1>Ramadhan First 10 Days</h1>

                            {/* <div className="DtlStatusCol">
                                <li><AccessTimeRoundedIcon /> 7:30 PM - 8:30 PM GMT-5</li>
                                <li><VideocamRoundedIcon /> Online</li>
                                <li><AttachMoneyRoundedIcon /> Free</li>
                                <li><GroupRoundedIcon /> Anyone can join</li>
                            </div> */}

                            {/* <div className="SpeekerContainer">
                                <h1>Speakers</h1>

                                <Grid container spacing={1}>

                                    <Grid item md={6} sm={6} xs={12}>
                                        <div className="Speekerchip">
                                            <div className="SpeekerIcon">
                                                <PersonRoundedIcon />
                                            </div>
                                            <div className="SpeekerDetails">
                                                <h2>Muhsin S. Mahdi</h2>
                                                <p>Professor of Arabic</p>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item md={6} sm={6} xs={12}>
                                        <div className="Speekerchip">
                                            <div className="SpeekerIcon">
                                                <PersonRoundedIcon />
                                            </div>
                                            <div className="SpeekerDetails">
                                                <h2>Imam Siraj Wahhaj</h2>
                                                <p>Imam, Masjid At-Taqwa, NY</p>
                                            </div>
                                        </div>
                                    </Grid>

                                </Grid>


                            </div> */}


                            {/* <div className="LocationContainer">
                                <h1>Event Location</h1>

                                <div className="accordionContainer">

                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} 
                                        aria-controls="panel1a-content" id="panel1a-header" >
                                            <Typography className="accordionTitle"><img src={Location} /> Location/Venue</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                 Lorem ipsum dolor sit amet, consectetur 
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} 
                                        aria-controls="panel1a-content" id="panel1a-header" >
                                            <Typography className="accordionTitle"><img src={Zoom} /> Zoom</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        Link: <a href="https://us04web.zoom.us/j/78012808408?pwd=S0lzL0RDdzFOYTlBcFNQNUVhWHJEUT09">https://us04web.zoom.us/j/78012808408?pwd=S0lzL0RDdzFOYTlBcFNQNUVhWHJEUT09</a> 
                                        <p>Topic: The Hajj Pilgrimage and Its Significance in Islam</p>
                                        <p>Topic: The Hajj Pilgrimage and Its Significance in Islam</p>
                                        <p>Time: Jan 22, 2022 02:00 PM Astana, Dhaka </p>
                                        <p>Meeting ID: 780 1280 8408 </p>
                                        <p>Passcode: Sx2DvH</p>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} 
                                        aria-controls="panel1a-content" id="panel1a-header" >
                                            <Typography className="accordionTitle"><img src={Facebook} /> Facebook</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                 Lorem ipsum dolor sit amet, consectetur 
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} 
                                        aria-controls="panel1a-content" id="panel1a-header" >
                                            <Typography className="accordionTitle"><img src={Youtube} /> YouTube</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                 Lorem ipsum dolor sit amet, consectetur 
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} 
                                        aria-controls="panel1a-content" id="panel1a-header" >
                                            <Typography className="accordionTitle"><img src={Phone} /> Teleconference</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                 Lorem ipsum dolor sit amet, consectetur 
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    
                                    
                                </div>

                            </div> */}

                        </div>
                    </Grid>

                </Grid>
            </div>
        );
    }
}


export default EventDetailsModalTwo;