import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "2 passport size color photo with white background",
    "1 passport size color photo with white background"
  ),
  createData(
    "Passport valid at least 6 months prior to Hajj flight",
    "Passport valid at least 6 months prior to Umrah flight"
  ),
  createData(
    "Copy of Green Card for Non-US/American Passport",
    "Copy of Green Card for Non-US/American Passport"
  ),
  createData(
    "Copy of Marriage Certificate for wife & Birth Certificate for child",
    ""
  ),
];

const RequirementsTable = () => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ fontSize: "22px" }}>
              HAJJ REQUIREMENT
            </TableCell>
            <TableCell align="center" style={{ fontSize: "22px" }}>
              UMRAH REQUIREMENT
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell align="center">{row.name}</TableCell>
              <TableCell align="center">{row.calories}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RequirementsTable;
