import React, { useState } from "react";

import ViewGallery from "./vg";
import Grid from "@material-ui/core/Grid";
import Lightbox from "react-image-lightbox";

import GalleryImg_1 from "../assets/imgs/Gallery/ZamZam_Hajj_Kafela_2016_Kaaba.png";
import GalleryImg_2 from "../assets/imgs/Gallery/ZamZam_Hajj_Kafela_2019_ToJamarat.png";
import GalleryImg_3 from "../assets/imgs/Gallery/ZamZam_Hajj_Kafela_2016_MIna_Camp_Site.png";
import GalleryImg_4 from "../assets/imgs/Gallery/ZamZam_Hajj_Kafela_2016_Mina.png";
import GalleryImg_5 from "../assets/imgs/Gallery/ZamZam_Hajj_Kafela_2016_Serving_Lunch.png";
import GalleryImg_6 from "../assets/imgs/Gallery/ZamZam_Hajj_Kafela_2019_Halaqah.png";
import GalleryImg_7 from "../assets/imgs/Gallery/ZamZam_Hajj_Kafela_2016_Jamarat.png";

const images = [
  GalleryImg_1,
  GalleryImg_2,
  GalleryImg_3,
  GalleryImg_4,
  GalleryImg_5,
  GalleryImg_6,
  GalleryImg_7,
];
const titleText = "Alhamdulillah. Serving for 13 years.";
const descriptionText =
  "Over hundreds have completed their sacred journey with us according to authentic Sunnah.";
const buttonText = "View Gallery";

function ViesGallerySection(props) {
  const [isOpen, setisOpen] = useState(false);

  const [photoIndex, setphotoIndex] = useState(0);

  const imageClick = (i) => {
    setphotoIndex(i);
    setisOpen(true);
  };

  return (
    <div>
      <ViewGallery
        images={images}
        titleText={titleText}
        descriptionText={descriptionText}
        buttonText={buttonText}
        buttonClicked={(e) => imageClick(0)}
        GalleryGrid={
          <div className="PhotoGalleryContainer">
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <div className="GelleryImgContaier imgsize_268">
                  <img
                    className=""
                    src={images[0]}
                    onClick={() => imageClick(0)}
                  />
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <div className="GelleryImgContaier imgsize_268">
                      <img
                        className=""
                        src={images[1]}
                        onClick={() => imageClick(1)}
                      />
                    </div>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item md={12} xs={12}>
                        <div className="GelleryImgContaier imgsize_130">
                          <img
                            className=""
                            src={images[2]}
                            onClick={() => imageClick(2)}
                          />
                        </div>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <div className="GelleryImgContaier imgsize_130">
                          <img src={images[3]} onClick={() => imageClick(3)} />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={12} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={3} xs={12}>
                    <div className="GelleryImgContaier imgsize_200">
                      <img
                        className=""
                        src={images[6]}
                        onClick={() => imageClick(4)}
                      />
                    </div>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <div className="GelleryImgContaier imgsize_200">
                      <img src={images[5]} onClick={() => imageClick(5)} />
                    </div>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <div className="GelleryImgContaier imgsize_200">
                      <img
                        className=""
                        src={images[4]}
                        onClick={() => imageClick(6)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={() => setisOpen(false)}
                onMovePrevRequest={() =>
                  setphotoIndex(
                    (photoIndex + images.length - 1) % images.length
                  )
                }
                onMoveNextRequest={() =>
                  setphotoIndex((photoIndex + 1) % images.length)
                }
                animationDisabled={false}
                animationOnKeyInput={true}
              />
            )}
          </div>
        }
      />
    </div>
  );
}

export default ViesGallerySection;
