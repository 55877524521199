import React, { Component } from 'react';
import { Testimonials } from "master-react-lib";

import MianUdin from '../assets/imgs/ZamZam_Hajj_Umrah_Main_Uddin_Shipon.jpeg'
import Abu_Bakar_Shiddque_Hashina_Akter from '../assets/imgs/ZamZamHajj_Umrah_Abu_Bakar_Shiddque.jpeg'
import Imam_Abdullatif_Azom from '../assets/imgs/ZamZam_Hajj_Umrah_Imam_Abdullatif_Azom.png'
import Osman_Gani from '../assets/imgs/ZamZam_Hajj_Umrah_Dr_Ataul_Osmani.jpeg'
import Kazi_Ismail from '../assets/imgs/ZamZam_Hajj_Umrah_Kazi_Ismail.png'
import Hassan_Abdul_Akbar from '../assets/imgs/ZamZam_Hajj_Umrah_Imam_Hassan_Abdul_Akbar.jpeg'
import Tazul_Islam from '../assets/imgs/ZamZam_Hajj_Umrah_Tazul_Islam.jpeg'
import LogoIcon from '../assets/imgs/ZamZam_Hajj_Umrah_LogoIcon.png';

import Shafik_Rahman from '../assets/imgs/ZamZam_Hajj_Umrah_Shafik_Rahman_Dulal.jpeg'
import Rahman_Kamal from '../assets/imgs/ZamZam_Hajj_Umrah_Mizanur_Rahman_Kamal.jpeg';




const users = [

  {
    id: 1,
    description:"Alhamdulillah it was a great experience 2019 Hajj with Zamzam Hajj group me and my wife I did Hajj before but 2019 was with Zamzam Awesome wonderful experience I was so comfortable with all the services if you follow the instruction from them really you will enjoy only thing too much food eat only when you are in need May Allah bless them with more baraka. Ameen.",
    name: "Imam Abdullatif Azom, Hajj Kafela 2019",
    address: "Detroit, MI",
    video: Imam_Abdullatif_Azom,
    img: Imam_Abdullatif_Azom,
  },
  
  {
    id: 2,
    description:"Assalamu alaykum everyone. I went to perform hajj with Zamzam Hajj Kafela in 2019, also did Umrah in 2017. That was an awesome experience. The important thing about this Hajj Kafela is that “whatever they promised about their service, Alhamdulillah they provided 99 percent of those services during the whole hajj period. Another important character of this hajj Kafela is that they keep the whole Kafela together; not splited in groups in Makkah and Madina same time, rather kept whole Kafela together in Mina, Makkah and Madina, so that we all received the same service and particularly the teachings of Islam provided by Imam Delwar, Sheikh Ismail and Hafiz Raihan. Last thing I want to say, Imam Delwar’s local team there is excellent under the leadership of Sheikh Ismail Makki, they provided splendid and fabulous service Alhamdulillah. Thanks Imam Delwar Hossain and his whole team for their excellent service. I recommend Zamzam Hajj Kafela without any reservation. May Allah SWT bless them, give them barakah and strength to continue their extended hajj and umrah services.",
    name: "Dr. Osman Gani, Hajj Kafela 2019",
    address: "Long Island, NY",
    video: Osman_Gani,
    img: Osman_Gani,
  },


  {
    id: 3,
    description:"His service was excellent , everything was accommodated nicely. All the hotels and food service was amazing. The transportation was smooth, organized and comfortable. The guide was well done and very professional. Would recommend using his service 100% such a dedicated and friendly service. ",
    name: "Kazi Ismail, Hajj Kafela 2019",
    address: "Brooklyn, NY",
    video: Kazi_Ismail,
    img: Kazi_Ismail,
  },

  {
    id: 4,
    description:"By far the most excellent Hajj experience I've ever had. The accommodations were 5 stars. It was a treat to have camel meat for the first time. I encourage everyone to use Zamzam Hajj Kafela if they want to feel the spirit of Hajj and Unity. ",
    name: "Imam Dr. Hassan Abdul Akbar, Hajj Kafela 2019",
    address: "Brooklyn, NY",
    video: Hassan_Abdul_Akbar,
    img: Hassan_Abdul_Akbar,
  },

  {
    id: 5,
    description:"Our experience with ZamZam Hajj Kafela was extremely pleasing and we were satisfied. From the airport experience to the hotel booking and stay, till the end of the hajj, everything was remarkable...Transportation,",
    name: "Main Uddin (Shipon) - Hajj Kafela 2018",
    address: "Ozone Park, NY",
    video: MianUdin,
    img: MianUdin,
  },

  {
    id: 6,
    description:"At first we were worried many things including taking our young children along but Alhamdulliah ZamZam Hajj Kafela made it extremely easy and painless for us.Once we reached Saudi Arabia, ZamZam Hajj Kafela arranged & managed everything very nicely including meals, hotel stays, transportation, and tents in Arafat, Mina & Muzdalifah... Allah willing we will go with Zamzam Hajj Kafela again in the future and I recommend everyone, who’s planning to go to Hajj or Umrah to use their excellent services.",
    name: "Tazul Islam - Hajj Kafela 2019",
    address: "Ozone Park, NY",
    video: Tazul_Islam,
    img: Tazul_Islam,
  },

  {
    id: 7,
    description:"Alhamdulillah by the grace and mercy of Almighty Allah SWT I had the privilege to perform hajj along with my spouse. As soon as I had made the intention I chose ZamZam Hajj Kafela to facilitate my Hajj journey. And throughout my journey I found them helpful, professional, and cooperative. I highly recommend ZamZam Hajj Kafela and wish them success in their future endeavors. ",
    name: "Aiman Hamid - Hajj Kafela 2019",
    address: "Brooklyn, NY",
    video: LogoIcon,
    img: LogoIcon,
  },


  {
    id: 8,
    description:"We made Hajj with ZamZam in 2019 and it was a pleasurable and once in a life time experience that my family will never forget. I went with my husband, brother in law, and my mother in law. All the accommodations were good and the team was transparent and made sure everyone was well during the trip. ",
    name: "Evgenia Sherstyuk, Hajj Kafela 2019",
    address: "Brooklyn, NY",
    video: LogoIcon,
    img: LogoIcon,
  },

  {
    id: 9,
    description:"Assalamualaikum, we decided to conduct our umrah with “ zam zam hajj and umrah”. From the beginning to end, imam Delowar Hossain was very professional. He answered all questions we had and made us very prepared for umrah. Once we arrived there, everything went as planned and he gave us many tips along the way to complete the umrah correctly. We very much recommend him and if we ever get the chance again, we will definitely choose the agency again inshAllah.",
    name: "Kamrun Nahar, Hajj Kafela Umrah 2018",
    address: "Brooklyn, NY",
    video: LogoIcon,
    img: LogoIcon,
  },


  {
    id: 10,
    description:"ZamZam Hajj Karelia is great Hajj Kafela in USA. I have successfully completed my Hajj with ZamZam Hajj Kafela at 2017. They are very good and help the people and provide training to the people every expect of Hajj. I wish their success.",
    name: "Mohammed Ibrahim Khalil, Hajj Kafela 2017",
    address: "Clementon, NJ",
    video: LogoIcon,
    img: LogoIcon,
  },


  {
    id: 11,
    description:"Alhamdulilah, we had a great experience with the ZamZam Hajj agency. As they stated, the full package was amazing; the flight was comfortable, the hotel was very close by, and we had great people along the way. The services provided such as food was spectacular and generally, it was all a dream come true. We hope everyone benefits from this service both in this world and the next. May Allah bless the people behind all of this hard work.",
    name: "Abu Bakar Shiddque Hashina Akter, Hajj Kafela 2017",
    address: "Buffalo, NY",
    video: Abu_Bakar_Shiddque_Hashina_Akter,
    img: Abu_Bakar_Shiddque_Hashina_Akter,
  },

  {
    id: 12,
    description:"AlHamdulliah, I had a great experience. The hotel, foods, transportation was world class and most importantly  daily teaching classes by imam Delwar Hossain was very beneficial for the whole group. I would recommend any of my friends to choose ZamZam Hajj kafela for an amazing Hajj and Omra trip.",
    name: "Mohammad Humayun Kabir, Hajj Kafela 2015",
    address: "Laurel Springs, NJ",
    video: LogoIcon,
    img: LogoIcon,
  },


  
  {
    id: 13,
    description:"Assalamualikum, as I know this service is really amazing I definitely recommend this service if you are going to Hajj or Umrah.",
    name: "Maulana Kazi Elias, Hajj Kafela 2018",
    address: "Clementon, NJ",
    video: LogoIcon,
    img: LogoIcon,
  },

  {
    id: 14,
    description:"ZamZam Team provided excellent service, when i went to Umrah last month. They are Very knowledgeable and professional. Specially brother Imam Delouer Hossain provided excellent service.",
    name: "Ruhel Ahmed, Umrah 2021",
    address: "Peterson, NJ",
    video: LogoIcon,
    img: LogoIcon,
  },

  {
    id: 15,
    description:"I went to do HAJJ with “zamzam hajj & umrah services” and imam Delaware he was very professional gave many tips on what was going to happen before we where Going to go on the trip.As we arrived he told us what we where going to do and what to do step by step.As I completed the hajj I was very satisfied with my trip and inshallah I go to another trip with them soon.",
    name: "Shafik Rahman Dulal, Hajj Kafela 2016",
    address: "Brooklyn, NY",
    video: Shafik_Rahman,
    img: Shafik_Rahman,
  },

  {
    id: 16,
    description:"Assalamualaikum warahmatullah, My wife and I went to Hajj in 2018 with Zam Zam Hajj. I was impressed by their service and the professionalism. Brother Imam Delower Hussein lead us on every events, we also gathered daily where imam talked about what and how to do also what not to do. It was very helpful for me and I think for everyone.  My brother also went to Hajj in the same year with other agency but he spent most of his time with us because of how Imam Delower and his team was helping us. My brother told me it’s a blessing that we went with Imam Delower Hossain. I would highly recommend Zam Zam Hajj to anyone that wants perform hajj according to sunnah.",
    name: "Mizanur Rahman Kamal, Hajj Kafela 2018",
    address: "Brooklyn, NY",
    video: Rahman_Kamal,
    img: Rahman_Kamal,
  },


  ];



class TestimonialsSection extends Component {

  render() {

    return (
      <div>
        <Testimonials
          users={users}
          index={16}
          duration={8000}
          PlayIcon={true}
        />
      </div>
    );
  }
}


export default TestimonialsSection;