import React from "react";
import HeaderSection from "../Common/HeaderSection";
import FooterSection from "../Common/FooterSection";
import { Grid } from "@material-ui/core";

const UmrahPage2025 = () => {
  return (
    <div className="MainContainer">
      <HeaderSection />
      <div className="innterContainer">
        <Grid container style={{ padding: "8px" }} spacing={1}>
          <Grid item xs={12} md={6}>
            <div className="umrahImageContainer">
              <img
                src="https://zamzamhajj-media.s3.amazonaws.com/zamzam-umrah-font-2025.png"
                alt="zamzam 2025 package"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="umrahImageContainer">
              <img
                src="https://zamzamhajj-media.s3.amazonaws.com/zamzam-umrah-back-2025.png"
                alt="zamzam 2025 package"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <FooterSection />
    </div>
  );
};

export default UmrahPage2025;
