import React, { Component } from "react";
import zamzamLogo from "../assets/imgs/zamzamlogo.png";
import Link from "@material-ui/core/Link";

import FacebookIcon from "../assets/imgs/socialicons/FacebookRounded.svg";
import YouTubeIcon from "../assets/imgs/socialicons/YouTubeRounded.svg";
import TwitterIcon from "../assets/imgs/socialicons/TwitterRounded.svg";
import WhatsAppIcon from "../assets/imgs/socialicons/WhatsappRounded.svg";

import "../assets/css/style.css";

class UmrahServices extends Component {
  render() {
    return (
      <div className="UmrahServicesContainer SectionContainer">
        <img src={zamzamLogo} alt="Logo" className="ZamzamLogoSmaill" />
        <h3 className="UmrahServicesTitle">
          ZamZam Hajj & Umrah Services, Inc
        </h3>
        <div className="UmrahServicesAddress">
          1049 Glenmore Ave, Brooklyn, NY 11208 <br /> +1 (917) 540-7944 <br />{" "}
          yeszamzam@gmail.com
        </div>

        <div className="SocialIcon">
          <Link
            href="https://www.facebook.com/pages/category/Tour-Guide/ZamZam-Hajj-Kafela-104054951900488/"
            target="blank"
          >
            <img src={FacebookIcon} alt="Logo" className="ZamzamLogoSmaill" />
          </Link>
          <Link
            href="https://www.youtube.com/channel/UCfe06KuigZlc3wtYpXJNRHw"
            target="blank"
          >
            <img src={YouTubeIcon} alt="Logo" className="ZamzamLogoSmaill" />
          </Link>
          {/* <Link><img src={TwitterIcon} alt="Logo" className="ZamzamLogoSmaill"/></Link>
                   <Link><img src={WhatsAppIcon} alt="Logo" className="ZamzamLogoSmaill"/></Link> */}
        </div>
      </div>
    );
  }
}

export default UmrahServices;
