import React, { Component } from 'react';
import HeaderSection from './Common/HeaderSection';
import CheckmarkIcons from '../assets/imgs/CheckmarkIcons.svg'






class ProcessedSuccessfully extends Component {

    render() {

        return (
            <div className="MainContainer">
                <HeaderSection />
                
                    <div className="ProSuccContainer">
                        <img src={CheckmarkIcons} />
                        <h1>Jazāk allāhu khayran</h1>
                        <div className="boderLine"></div>
                        <p className="TextGreen">A payment of $——- processed successfully. </p>
                        <p>We sent an email with reservation confirmation for Hajj Kafela 2021. With Salaam - ZamZam Team</p>
                    </div>

                   
               
            </div>
        );
    }
}


export default ProcessedSuccessfully;