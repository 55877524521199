import React, { Component } from 'react';
import { ZamZamVideos } from "master-react-lib";
import Mosque from '../assets/imgs/mosque.png';


const carouselItems = [
    { image: Mosque, videoId:"FETryXMpDl8", description: "Episode 1 - What is Hajj and how to be prepared?",  },
    { image: Mosque, videoId:"FETryXMpDl8", description: "Islam, the Quran, and the Five Pillars All Without...",  },
    { image: Mosque, videoId:"FETryXMpDl8", description: "Islam, Judaism, and Christianity - A Conversation",  },
    { image: Mosque, videoId:"FETryXMpDl8", description: "Episode 1 - What is Hajj and how to be prepared?",  },
    { image: Mosque, videoId:"FETryXMpDl8", description: "Episode 1 - What is Hajj and how to be prepared?",  },
    { image: Mosque, videoId:"FETryXMpDl8", description: "Episode 1 - What is Hajj and how to be prepared?",  },
  ];



class ZamZamVideosSection extends Component {

    render() {

        return (
            <div id="videos" className="SectionContainer SectionGrey">
                <ZamZamVideos 
                    title="ZamZam Videos"
                    carouselItems={carouselItems}
                />
            </div>
        );
    }
}


export default ZamZamVideosSection;