import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/core/styles";
// import { Grid } from "@mui/material";
import TextField from "./TextFiled";
import PhoneInputField from "./PhoneFeild";
import "../styles/Modal.css";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    // margin: theme.spacing(1),
    padding: "15px 0",
    width: "100%",
    background: "#56B149",
    color: "#fff",
    boxShadow: "none",
    "&:hover": {
      background: "#56B149",
      color: "#fff",
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
}));

export default function WelcomeModal(props) {
  // const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [email, setEmail] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = React.useState(true);

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleChanges = (fieldName) => (event) => {
    if (fieldName === "firstName") {
      setfirstName(event);
    }
    if (fieldName === "lastName") {
      setlastName(event);
    }
    if (fieldName === "email") {
      setEmail(event);
    }
  };

  // const handleToast = () => {
  //   alert("hello");
  // };

  return (
    <div className="ModalParentContainer">
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleClose}
        className={classes.root}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{ padding: "0px" }}>
          <h1 className="ModalHeader" style={{ color: "#0B132B" }}>
            JOIN ZAMZAM FOR <br /> UPDATES AND OFFERS
          </h1>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>

          </DialogContentText> */}
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label="First name"
                required={false}
                error=""
                value={firstName}
                handleChange={handleChanges("firstName")}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label="Last name"
                value={lastName}
                handleChange={handleChanges("lastName")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                label="Email address (Optional)"
                email={true}
                value={email}
                handleChange={handleChanges("email")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <PhoneInputField
                phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                country={"us"}
                // value={phone.number}
                countryCodeEditable={false}
                // onChange={(phone, country) => handlePhoneChange(phone)}
                onBlur={() => console.log("onBlur")}
                // onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                variant="outlined"
                // onlyCountries={["us", "ca", "gb", "my"]}
                containerStyle={{}}
                buttonStyle={{}}
                inputStyle={{
                  marginLeft: "0px",
                  height: "40px",
                  width: "100%",
                  fontFamily: "Roboto-Regular",
                  fontSize: "16px",
                }}
                containerClass={{}}
                errorText={"Required"}
                // error={error.phone ? "error" : ""}
                errorStyles={{ marginBottom: "5px" }}
                autoFormat={true}
                disableDropdown={false}
                disabled={false}
                disableCountryCode={false}
                enableSearch={false}
                disableSearchIcon={false}
                placeholder={""}
                inputProps={{}}
                specialLabel={"Primary Phone"}
                // preferredCountries={[]}
                // excludeCountries={[]}
                labelFixed="Phone number"
                LabelStatus={true}
                // helpertext="Helper text"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormControlLabel
                style={{ alignItems: "flex-start" }}
                control={
                  <Checkbox
                    name="checkedG"
                    style={{ padding: "0px", margin: "0 8px" }}
                    checked={checked}
                    onChange={handleCheckChange}
                  />
                }
                label="I agree to receive messages, notifications, and promotions from ZamZam at the above number and email address provided."
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              props.handleClose();
              // handleToast();
            }}
            // color="primary"
            variant="contained"
            className={classes.button}
            // style={{ margin: "0 16px" }}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
