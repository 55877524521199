import React from "react";
import FooterSection from "../Common/FooterSection";
import { Grid } from "@material-ui/core";
import HeaderSection from "../Common/HeaderSection";

const PrivacyPolicy = () => {
  const formatDate = () => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date().toLocaleDateString("en-US", options);
  };

  return (
    <div>
      <div>
        <div className="MainContainer">
          <HeaderSection />
          <div className="innterContainer">
            <Grid container style={{ padding: "16px" }}>
              <Grid item xs={12}>
                <div className="TosContainer">
                  <h1 className="InnerPageTitle">Privacy Policy</h1>
                  <span className="TosDate">LAST UPDATED: JAN 13, 2024</span>
                  <br />
                  <br />
                  <p>
                    This Privacy Policy explains how ZamZam Hajj, a travel
                    services provider for Umrah and Hajj, manages and protects
                    your personal information. By using ZamZam Hajj's services,
                    you agree to the terms outlined below.
                  </p>
                  <br />
                  <p>
                    ZamZam Hajj is dedicated to preserving the privacy of your
                    personal information and employs various safeguards to
                    ensure its security. As the provider of travel services, we
                    act as the "data controller" under the General Data
                    Protection Regulation 2016/679 ("GDPR"), making us
                    responsible for the personal information you share with us.
                  </p>
                  <p>
                    When you share your personal information with us, you
                    consent to its collection, use, and disclosure as described
                    in this Notice. If you disagree with any part of this
                    Notice, please avoid providing your personal information, as
                    it may impact our ability to serve you.
                  </p>
                  <p>
                    This Notice covers ZamZam Hajj's privacy practices and is
                    effective as of [{formatDate()}]. Please check this Notice
                    periodically for updates, as we may amend it. Significant
                    changes will be prominently communicated on our website.
                  </p>
                  <h2>2. What personal information do we collect?</h2>
                  <p>
                    At ZamZam Hajj, we collect and process personal information
                    essential for organizing your travel arrangements for Umrah
                    and Hajj. The types of personal information we collect
                    include:
                  </p>
                  <p>
                    Names and contact details: This includes your phone number,
                    email address, and address.
                  </p>
                  <p>
                    Details of your visits to our website: Information about
                    your interactions with our website, such as traffic data,
                    location data, and weblogs. Information provided in forms:
                    When you fill in forms on our website, like registration or
                    purchase forms for travel products.
                  </p>
                  <p>
                    Communication information: Details provided when you contact
                    us for any reason.
                  </p>
                  <p>
                    In some cases, the collected information may be considered
                    sensitive personal data under relevant data protection laws.
                    We collect such information only with your explicit consent,
                    especially when it involves your religious beliefs, health,
                    or other sensitive details. We ensure that sensitive
                    information is handled in compliance with local data
                    protection laws, with your explicit consent and only for
                    purposes directly related to our functions or activities.
                  </p>
                  <h2>3- How do we collect personal information?</h2>
                  <p>
                    We prioritize transparency and fairness in collecting
                    personal information, adhering to local data protection
                    laws. Our primary method is direct collection from you,
                    except when unreasonable or impractical. Collection methods
                    include:
                  </p>
                  <ul>
                    <li>
                      Direct Interaction: In-person, by telephone, letter, or
                      email when engaging with us for travel-related services.
                    </li>
                    <li>
                      Website Visits: Information collected during your visits
                      to our websites, encompassing traffic data, location data,
                      and weblogs.
                    </li>
                    <li>
                      Social Media: Information shared during interactions when
                      you connect with us via social media.
                    </li>
                    <li>
                      Travel Arrangements: Information gathered when you inquire
                      about or purchase travel-related products and services.
                    </li>
                    <li>
                      Competitions, Promotions, Subscriptions: Details provided
                      when participating in competitions, promotions, or
                      subscribing for marketing materials.
                    </li>
                    <li>
                      Third-party Bookings: When someone makes a booking on your
                      behalf, relying on their authority.
                    </li>
                  </ul>
                  <p>
                    Maintaining accurate and complete information is crucial,
                    and we encourage you to promptly update us on any changes or
                    inaccuracies for efficient service.
                  </p>
                  <h2>4. How do we use your personal information? </h2>
                  <p>
                    We process your information in the following situations:
                  </p>
                  <ul>
                    <li>
                      Consent: Processing information when you've given consent,
                      which you can withdraw anytime.
                    </li>
                    <li>
                      Service Provision: Processing information essential for
                      providing services, including travel bookings and managing
                      related products.
                    </li>
                    <li>
                      Legal Obligations: Processing information to comply with
                      legal obligations.
                    </li>
                    <li>
                      Legitimate Interests: Processing information for our
                      legitimate interests or third-party recipients, detailed
                      below.
                    </li>
                  </ul>
                  <p>Specifically:</p>
                  <ul>
                    <li>
                      Travel Bookings and Queries: Collecting information for
                      travel advice, bookings, and offering travel-related
                      services.
                    </li>
                    <li>
                      Acting as an Agent: Processing information to provide
                      services and sharing it with relevant providers.
                    </li>
                    <li>
                      Personalization and Marketing: Using information for
                      tailored services, preferences-based options, and targeted
                      marketing, if opted in.
                    </li>
                    <li>
                      Research and Analysis: Using information for business
                      trends, customer preferences, and website use analysis.
                    </li>
                    <li>
                      Legal Compliance: Processing information to comply with
                      legal and travel-related requirements.
                    </li>
                    <li>
                      Other Purposes: Using information for internal processes,
                      loyalty programs, research, and customer satisfaction
                      evaluation.
                    </li>
                  </ul>
                  <p>
                    You can volunteer to opt in to receive notifications and
                    emails to your email and phone number, providing an
                    additional layer of personalized communication.
                  </p>
                  <h2>
                    5. Is personal information disclosed to third parties?
                  </h2>
                  <p>
                    Maintaining the confidentiality of your personal information
                    is our priority; hence, we do not sell, rent, or trade it.
                    Disclosure adheres to this Notice and local data protection
                    laws, covering transfer, sharing, sending, or making your
                    information available to others.
                  </p>
                  <p>Your information may be disclosed to:</p>
                  <ul>
                    <li>
                      Contractors, Suppliers, and Service Providers: Including
                      IT providers, publishers, printers, marketing agencies,
                      and external advisers, as outlined in section 4.
                    </li>
                    <li>
                      Related Entities and Brands: Global disclosure for travel
                      bookings and administrative, advisory, and technical
                      services.
                    </li>
                    <li>
                      Travel Service Providers: Relevant overseas providers,
                      such as wholesalers, tour operators, airlines, hotels, and
                      car rentals.
                    </li>
                    <li>
                      Third-Party Service Providers: Disclosing information to
                      third parties overseas for services like storage and
                      processing.
                    </li>
                    <li>
                      Other Disclosures: As required by law, customs/immigration
                      requirements, or legal obligations, involving government
                      agencies and law enforcement in suspected unlawful
                      activities.
                    </li>
                  </ul>
                  <p>
                    We don't accept liability for how overseas recipients handle
                    your information, considering local data protection laws.
                  </p>
                  <h2>6. Is personal information transferred overseas?</h2>
                  <p>
                    We may disclose your information to overseas recipients,
                    ensuring it's necessary for a contract or subject to
                    safeguards required by local data protection laws (e.g.,
                    GDPR). Overseas recipients include:
                  </p>
                  <p>
                    Third-Party Service Providers: Performing services like
                    storage and processing. Due to our global dealings, specific
                    countries for data transfer may vary. For details, refer to
                    the "Feedback / Complaints / Contact" section below (section
                    11).
                  </p>
                  <h2>7. Security of Information</h2>
                  <p>
                    We are dedicated to ensuring the security of your personal
                    information. To achieve this, we implement and maintain
                    appropriate technical and organizational measures that
                    provide a level of security suitable for safeguarding the
                    information. Our security protocols aim to prevent
                    accidental or unlawful destruction, loss, alteration,
                    unauthorized access, disclosure, or processing of personal
                    information.
                  </p>
                  <p>
                    ZamZam Hajj employs various physical, electronic, and
                    managerial security procedures to protect personal
                    information from loss, misuse, unauthorized access,
                    modification, disclosure, and interference. We regularly
                    review security technologies to ensure comprehensive
                    protection.
                  </p>
                  <p>
                    While we take extensive measures to secure personal
                    information, we cannot be held responsible for the actions
                    or security controls of third parties regarding information
                    collected or processed through their websites, services, or
                    other means.
                  </p>
                  <p>
                    We adhere to the principle of destroying or de-identifying
                    personal information when it is no longer necessary for our
                    business purposes or as required by law.{" "}
                  </p>
                  <h2>
                    8. Your Rights Regarding Collected Personal Information
                  </h2>
                  <p>If you want to:</p>
                  <ul>
                    <li>
                      Update, modify, delete, or obtain a copy of the personal
                      information we hold about you.
                    </li>
                    <li>
                      Restrict or stop us from using any personal information we
                      hold about you, including withdrawing previously given
                      consent.
                    </li>
                    <li>
                      Request a copy of personal information processed based on
                      your consent or as necessary for a contract.
                    </li>
                  </ul>
                  <p>
                    You can make these requests by emailing us at the address
                    provided in section 11 below. Upon receiving your request,
                    we will acknowledge it and inform you of the timeframe for
                    receiving your information pack.
                  </p>
                  <p>
                    We aim to respond within a month or less, with the right to
                    extend this period for complex requests. Exemptions under
                    applicable laws may allow us to deny access or correction,
                    in which case we will provide written reasons for denial,
                    noting your request and the denial in our records, as
                    required by local data protection laws. Note that if you
                    request restrictions or cessation of using your personal
                    information, or withdraw consent, it may impact our ability
                    to provide services or affect the services we can offer.
                    Travel bookings, for example, require accurate traveler
                    information.
                  </p>
                  <p>
                    Ensure provided information is accurate and promptly update
                    us with any changes. In the absence of updates, we assume
                    the submitted information is correct unless we become aware
                    of its inaccuracy.
                  </p>
                  <p>
                    You can inform us at any time not to send you marketing
                    communications by email using the unsubscribe link in
                    marketing emails or contacting us as indicated in section
                    11. For these situations, we may request proof of identity
                    for security purposes.
                  </p>
                  <h2>9. IP Addresses</h2>
                  <p>
                    When you visit our website, use our services, or open emails
                    from us, our servers may note details about your device and
                    the network used to connect, including your IP address (a
                    set of numbers identifying your computer).
                  </p>
                  <p>
                    IP addresses might be used for system management,
                    investigating security issues, and compiling anonymous data
                    about website and/or service use. They could also be linked
                    to other personal information we have about you, helping
                    tailor marketing and advertising materials (if you opted in
                    for electronic marketing).
                  </p>
                  <h2>10. Tracking Technologies/Cookies</h2>
                  <p>
                    We might use third-party web analytics services (as listed
                    in our Cookies Policy) on our websites and mobile apps.
                    These services use technologies like cookies and web beacons
                    to analyze how visitors use our sites and apps.
                  </p>
                  <p>
                    For more about our use of cookies and tracking technologies,
                    check our Cookies Policy on our website.
                  </p>
                  <h2>11. Feedback/Complaints/Contact</h2>
                  <p>
                    If you have questions, comments, or complaints about this
                    Notice or how we handle your personal information, or if you
                    want to update or correct your details, request information
                    we collected about you, or make a complaint or comment,
                    please contact your consultant or the Data Protection
                    Officer with the details below:
                  </p>
                  <p>
                    Email: info@ZamZamHajj.com <br /> Post: Data Protection
                    Officer, ZamZam Hajj, 1049 Glenmore Ave, #5B, Brooklyn, NY
                    11208
                  </p>
                  <p>
                    We'll get back to any inquiries or complaints as soon as we
                    can.
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
